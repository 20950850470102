import React from 'react';
import * as cheerio from 'cheerio';
import * as quotedPrintable from 'quoted-printable';
import qs from 'qs';
import axios from 'axios';
import _ from 'lodash';
import DEFAULT_PLANS from '@mocks/plans.json';
import SMS_PLANS_ADDONS from '@mocks/addons.json';
import testPlans from '@mocks/testPlans.json';

import { IMedia, ILocalesModel, IPagination } from 'types/strapi';
import {
  IPlan,
  IPlanFeature,
  IPlanFeatureDetail,
  ISMSPlan,
  ISMSPlanDetail,
  PDFLicense,
} from 'types/pricing';
import { IToken } from 'types/common';
import { ParsedDetails } from 'types/api';
import Logger from '@utils/logger/client';
import { toast } from 'react-toastify';
import AdjustablePriceComponent from '@components/pricing/compareplandescription/AdjustablePriceComponent';
import { SelectedPriceOption } from '@context/globalState';
import { CompareData } from 'types/strapi/language/pricing';
import { MEDIA_URL } from '../config';
import Toggle from '../components/pricing/customtablesharecomponents/toggle';
import NumberInput from '../components/pricing/customtablesharecomponents/number';
import { validateUser } from './api';

export const defaultCurrency = {
  name: 'USD',
  symbol: '$',
  code: 'USD',
};

export const isValueTrue = (value: any) => {
  if (value === 'coming_soon') return false;
  if (value === 'contact_sales') return false;
  return (
    value === 'true' ||
    value === true ||
    value?.toLowerCase?.() === 'true' ||
    !!value?.includes?.('user')
  );
};

export const renderLanguageName = (code: string, locales?: ILocalesModel[]) => {
  if (!locales?.length) {
    return 'English';
  }
  return (
    locales.filter((item) => item.language_code == code)?.[0]?.display_name ||
    'English'
  );
};

export const SIMPLE_RICH_TEXT = 'text-section.simple-text-section';
export const RICH_TEXT_WITH_TITLE =
  'text-section-with-title.text-section-with-title';
export const RICH_TEXT_FEATURED_SECTION = 'featured-section.featured-section';
export const RICH_TEXT_IMAGE = 'image.image';
export const RICH_TEXT_VIDEO = 'video.video';

export const getMediaExtension = (media?: IMedia) => {
  if (media?.data?.attributes?.mime) {
    return media?.data?.attributes?.mime || '';
  }
  return '';
};

export const getMediaType = (media?: IMedia) => {
  if (media?.data?.attributes?.mime) {
    return (_.get(media, 'data.attributes.mime')! as string)?.includes('video')
      ? 'video'
      : 'image';
  }
  return 'image';
};

export const getImageUrl = (image?: IMedia, highQuality = false) => {
  if (typeof image === 'string') {
    if (highQuality) {
      return `${image} 2x`;
    }
    return image;
  }
  let url =
    _.get(image, 'data.attributes.url') ||
    _.get(image, 'attributes.url') ||
    '/logo-light.svg';
  if (url === '/logo-light.svg') {
    return '';
  }
  if (url.startsWith('data:image') || url.startsWith('data:video')) {
    return url;
  }
  if (/^[a-zA-Z]/.test(url) && !/^https?:\/\//.test(url)) {
    url = `https://${url}`;
  } else if (url.startsWith('/')) {
    url = MEDIA_URL + url;
  }
  const isVideo = getMediaType(image) === 'video';
  if (
    image &&
    isVideo &&
    !url.includes('https://') &&
    !url.includes('http://')
  ) {
    url = `https://${url}`;
  }
  if (isVideo) {
    return url;
  }
  if (highQuality) {
    return `${url} 2x`;
  }
  return url;
};

export const getImagePlaceholder = (image?: IMedia) => {
  if (image?.data?.attributes?.placeholder) {
    return image?.data?.attributes?.placeholder;
  }
  return '';
};

export const INT_MAX = 2147483647;

export const isUnlimited = (value: number | string) =>
  Number.isNaN(parseInt(value as string, 10)) ||
  (value as number) >= INT_MAX ||
  parseInt(value as string, 10) >= INT_MAX;

export const getUnlimitedValue = (value: number | string) =>
  isUnlimited(value) ? INT_MAX : parseInt(value as string, 10);

export const checkIsStringUnlimited = (value: string) => {
  const valueToCheck = value?.toLowerCase?.() || '';
  return valueToCheck === 'unlimited' || valueToCheck.includes('unlimited');
};

export const checkIsStringANumber = (value: string) =>
  !Number.isNaN(parseInt(value, 10));

export const checkIsStringComingSoon = (value: string) => {
  const valueToCheck = value?.toLowerCase?.() || '';
  return (
    valueToCheck === 'coming soon' ||
    valueToCheck.includes('coming soon') ||
    valueToCheck.includes('coming_soon') ||
    valueToCheck.includes('soon') ||
    valueToCheck.includes('coming')
  );
};

export const checkIsStringContactSales = (value: string) => {
  const valueToCheck = value?.toLowerCase?.() || '';
  return (
    valueToCheck === 'contact sales' ||
    valueToCheck.includes('contact sales') ||
    valueToCheck.includes('contact_sales') ||
    valueToCheck.includes('sales') ||
    valueToCheck.includes('contact')
  );
};

export const getImageDimensions = (image?: IMedia) => {
  if (image?.data?.attributes?.width) {
    const width = _.get(image, 'data.attributes.width') || 0;
    const height = _.get(image, 'data.attributes.height') || 0;
    return {
      width,
      height,
    };
  }
  return {
    width: 0,
    height: 0,
  };
};

export const getQueryString = (
  config: string[],
  locale = 'en',
  sort?: string[],
  pagination?: IPagination,
  filters?: string[],
) =>
  qs.stringify(
    {
      populate: config,
      locale,
      ...(sort ? { sort } : {}),
      ...(pagination ? { pagination } : {}),
      ...(filters ? { filters } : {}),
    },
    {
      encodeValuesOnly: true,
    },
  );

export const getBlogFilterQueryString = (
  currentLocale = 'en',
  searchField: string,
) =>
  qs.stringify(
    {
      populate: ['user_image,display_image,primary_topic,topics'],
      locale: currentLocale,
      sort: ['date_created:desc'],
      pagination: { page: 1, pageSize: 50 },
      filters: {
        $or: [
          {
            short_description: {
              $containsi: searchField.toLowerCase(),
            },
          },
          {
            slug: {
              $containsi: searchField.toLowerCase(),
            },
          },
          {
            title: {
              $containsi: searchField.toLowerCase(),
            },
          },
          {
            user_description: {
              $containsi: searchField.toLowerCase(),
            },
          },
          {
            user_name: {
              $containsi: searchField.toLowerCase(),
            },
          },
          {
            primary_topic: {
              topic_name: {
                $containsi: searchField.toLowerCase(),
              },
            },
          },
          {
            primary_topic: {
              slug: {
                $containsi: searchField.toLowerCase(),
              },
            },
          },
        ],
      },
    },
    {
      encodeValuesOnly: true,
    },
  );

export const SOCIALS = {
  twitter: 'http://twitter.com/intent/tweet/?url=',
  facebook: 'http://www.facebook.com/sharer.php?u=',
};

export function Container({ children, id }: { children: any; id: string }) {
  return (
    <p
      id={id}
      className="font-inter-hero text-sm leading-5 font-semibold text-primary-600 dark:text-primary-500 xl:text-base xl:leading-6 xl:font-semibold"
    >
      {children}
    </p>
  );
}

export function PlanFeature({
  plan,
  index,
  commonData = {},
  setCustomisePlan,
  onPlanChange,
  planIndex,
  detailIndex,
  detailInnerIndex,
}: {
  plan: IPlanFeatureDetail;
  index: 1 | 2 | 3 | 4;
  commonData?: any;
  setCustomisePlan: React.Dispatch<React.SetStateAction<boolean>>;
  onPlanChange: any;
  planIndex: number;
  detailIndex: number;
  detailInnerIndex?: number;
}) {
  const id = `plan_${plan.detail_id}-${index}`;
  const value = plan[`plan_${index}_value`];
  if (checkIsStringUnlimited(value)) {
    return <Container id={id}>{commonData.unlimited}</Container>;
  }
  if (plan.type === 'boolean') {
    if (checkIsStringANumber(value)) {
      return <Container id={id}>{parseInt(value, 10)}</Container>;
    }
    if (isValueTrue(value)) {
      return (
        <Container id={id}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.7071 6.29289C20.0976 6.68342 20.0976 7.31658 19.7071 7.70711L9.70711 17.7071C9.31658 18.0976 8.68342 18.0976 8.29289 17.7071L4.29289 13.7071C3.90237 13.3166 3.90237 12.6834 4.29289 12.2929C4.68342 11.9024 5.31658 11.9024 5.70711 12.2929L9 15.5858L18.2929 6.29289C18.6834 5.90237 19.3166 5.90237 19.7071 6.29289Z"
              fill="currentColor"
            />
          </svg>
        </Container>
      );
    }
    if (value === 'false') {
      return (
        <Container id={id}>
          <button
            id={`button_${id}`}
            className="text-primary-600 dark:text-primary-500"
            onClick={() => {
              setCustomisePlan(true);
              onPlanChange(
                index - 1,
                planIndex,
                detailIndex,
                true,
                detailInnerIndex,
              );
            }}
          >
            {commonData.add}
          </button>
        </Container>
      );
    }
    return (
      <Container id={id}>
        <span className="text-gray-600 dark:text-gray-400">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.63796 6.36327C7.28649 6.0118 6.71664 6.0118 6.36517 6.36327C6.01369 6.71475 6.01369 7.28459 6.36517 7.63607L10.3252 11.5961L6.36532 15.556C6.01385 15.9075 6.01385 16.4773 6.36532 16.8288C6.71679 17.1803 7.28664 17.1803 7.63811 16.8288L11.598 12.8689L15.5576 16.8285C15.909 17.1799 16.4789 17.1799 16.8303 16.8285C17.1818 16.477 17.1818 15.9071 16.8303 15.5557L12.8708 11.5961L16.8305 7.6364C17.182 7.28493 17.182 6.71508 16.8305 6.36361C16.479 6.01213 15.9092 6.01213 15.5577 6.36361L11.598 10.3233L7.63796 6.36327Z"
              fill="currentColor"
            />
          </svg>
        </span>
      </Container>
    );
  }
  return <Container id={id}>{value}</Container>;
}

function MobileContainer({ children, id }: { children: any; id: string }) {
  return (
    <p
      id={id}
      className="font-inter-hero text-sm leading-5 text-primary-600 dark:text-primary-500"
    >
      {children}
    </p>
  );
}
export function PlanFeatureMobile({
  plan,
  index,
  commonData = {},
  setCustomisePlan,
  onPlanChange,
  planIndex,
  detailIndex,
  detailInnerIndex,
}: {
  plan: IPlanFeatureDetail;
  index: 1 | 2 | 3 | 4;
  commonData?: any;
  setCustomisePlan: React.Dispatch<React.SetStateAction<boolean>>;
  onPlanChange: any;
  planIndex: number;
  detailIndex: number;
  detailInnerIndex?: number;
}) {
  const id = `mobile_plan_${plan.detail_id}-${index}`;
  const value = plan[`plan_${index}_value`];
  if (checkIsStringUnlimited(value)) {
    return <MobileContainer id={id}>{commonData.unlimited}</MobileContainer>;
  }
  if (plan.type === 'boolean') {
    if (checkIsStringANumber(value)) {
      return <MobileContainer id={id}>{parseInt(value, 10)}</MobileContainer>;
    }
    if (isValueTrue(value)) {
      return (
        <MobileContainer id={id}>
          <svg
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.7071 0.292893C16.0976 0.683417 16.0976 1.31658 15.7071 1.70711L5.70711 11.7071C5.31658 12.0976 4.68342 12.0976 4.29289 11.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L5 9.58579L14.2929 0.292893C14.6834 -0.0976311 15.3166 -0.0976311 15.7071 0.292893Z"
              fill="currentColor"
            />
          </svg>
        </MobileContainer>
      );
    }
    if (value === 'false') {
      return (
        <MobileContainer id={id}>
          <button
            id={`button_${id}`}
            className="text-primary-600 dark:text-primary-500"
            onClick={() => {
              setCustomisePlan(true);
              onPlanChange(
                index - 1,
                planIndex,
                detailIndex,
                true,
                detailInnerIndex,
              );
            }}
          >
            {commonData.add}
          </button>
        </MobileContainer>
      );
    }
    return (
      <MobileContainer id={id}>
        <span className="text-gray-600 dark:text-gray-400">x</span>
      </MobileContainer>
    );
  }
  return <MobileContainer id={id}>{value}</MobileContainer>;
}

export const getCustomiseAblePlanComponent = (
  plan: IPlanFeatureDetail,
  index: 1 | 2 | 3 | 4,
  onPlanChange: () => void,
  planIndex: number,
  detailIndex: number,
  commonData = {},
  setCustomisePlan: React.Dispatch<React.SetStateAction<boolean>>,
  detailIndexI?: number,
  data?: CompareData,
  smsPlans?: ISMSPlan[],
  licensePrices?: PDFLicense[],
) => {
  const id = `${plan.detail_id}-${index}`;
  const is_customisable = plan[`is_editable_for_plan_${index}`];
  if (!is_customisable) {
    return (
      <div id={id}>
        <PlanFeature
          plan={plan}
          index={index}
          commonData={commonData}
          onPlanChange={onPlanChange}
          setCustomisePlan={setCustomisePlan}
          planIndex={planIndex}
          detailIndex={detailIndex}
          detailInnerIndex={detailIndexI}
        />
      </div>
    );
  }
  const defaultValue = plan[`plan_${index}_value`];
  if (plan.type == 'boolean') {
    const isAdjustablePricing = plan.adjustable_pricing;
    if (isAdjustablePricing) {
      const isChecked = isValueTrue(plan[`plan_${index}_value`]);
      if (isChecked) {
        return (
          <AdjustablePriceComponent
            defaultValue={defaultValue}
            onPlanChange={onPlanChange}
            plan={index - 1}
            planIndex={planIndex}
            detailIndex={detailIndex}
            id={id}
            detailInnerIndex={detailIndexI}
            data={data}
            smsPlans={smsPlans}
            licensePrices={licensePrices}
          />
        );
      }
    }
    return (
      <Toggle
        defaultValue={defaultValue}
        onPlanChange={onPlanChange}
        plan={index - 1}
        planIndex={planIndex}
        detailIndex={detailIndex}
        id={id}
        detailInnerIndex={detailIndexI}
      />
    );
  }
  if (plan.type == 'integer') {
    return (
      <NumberInput
        defaultValue={defaultValue}
        onPlanChange={onPlanChange}
        plan={index - 1}
        planIndex={planIndex}
        detailIndex={detailIndex}
        id={id}
        detailInnerIndex={detailIndexI}
      />
    );
  }
  return '';
};

export const prependPreviewLink = (
  link = '/',
  _isPreview?: any,
  locale = 'en',
) => {
  if (link?.includes('mailto:')) {
    return link;
  }
  return `/${locale}${link}`;
};

export const STRIPE_CURRENCIES = [
  'USD',
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ARS',
  'AUD',
  'AZN',
  'BAM',
  'BDT',
  'BGN',
  'BIF',
  'BND',
  'BOB',
  'BRL',
  'BWP',
  'BYN',
  'BZD',
  'CAD',
  'CDF',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'EUR',
  'GBP',
  'GEL',
  'GNF',
  'GTQ',
  'HKD',
  'HNL',
  'HRK',
  'IDR',
  'ILS',
  'INR',
  'ISK',
  'JMD',
  'JPY',
  'KES',
  'KMF',
  'KZT',
  'LBP',
  'LKR',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MOP',
  'MUR',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'PAB',
  'PEN',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SEK',
  'SGD',
  'SOS',
  'THB',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'UYU',
  'UZS',
  'VND',
  'XAF',
  'XOF',
  'YER',
  'ZAR',
];

export const findPosition = (obj: {
  offsetTop: number;
  offsetParent?: { offsetTop: number };
}) => {
  let currenttop = 0;
  if (obj.offsetParent) {
    do {
      currenttop += obj.offsetTop;
    } while ((obj = obj.offsetParent));
    return currenttop;
  }
};

export const getFeatureById = (features: IPlanFeature[], id: string) => {
  try {
    const flatten = (arr: any[]): any =>
      arr.reduce(
        (acc, val) =>
          Array.isArray(val) ? acc.concat(flatten(val)) : acc.concat(val),
        [],
      );
    let flattenFeatures = flatten(
      features.map((feature) => feature?.details || []),
    );
    flattenFeatures = flatten(
      flattenFeatures.map((feature: any) =>
        feature?.details?.length ? feature?.details : feature,
      ),
    );
    return (
      flattenFeatures.find((feature: any) => feature?.detail_id === id) || null
    );
  } catch (err) {
    return null;
  }
};

export function roundUp(value: number) {
  // eslint-disable-next-line no-unused-vars
  const [intPart, decimalPart] = value.toString().split('.');
  if (!decimalPart || decimalPart.length < 3) {
    return value;
  }
  if (parseInt(decimalPart.charAt(2), 10) >= 5) {
    return Math.ceil(value * 100) / 100;
  }
  return Math.trunc(value * 100) / 100;
}

export const isFeatureEnabled = (
  features: IPlanFeature[],
  id: string,
  index: number,
) => {
  const valueId = `plan_${index}_value` as keyof IPlanFeatureDetail;
  const featureDetail = getFeatureById(features, id) as IPlanFeatureDetail;
  return isValueTrue(featureDetail?.[valueId]);
};

export const getActivatedFeatures = (
  features: IPlanFeature[],
  index: number,
  planId: string,
) => {
  const valueId = `plan_${index}_value` as keyof IPlanFeatureDetail;
  const access_dny = getFeatureById(features, 'access_dny');
  const prom_adm = getFeatureById(features, 'prom_adm');
  const block_usr = getFeatureById(features, 'block_usr');
  const power_usr = getFeatureById(features, 'power_usr');
  const guest_usr = getFeatureById(features, 'guest_usr');
  const allow_dev = getFeatureById(features, 'allow_dev');
  const paus_syn = getFeatureById(features, 'paus_syn');
  const wipe_rmt = getFeatureById(features, 'wipe_rmt');
  const perm_itm = getFeatureById(features, 'perm_itm');
  const keep_rmt = getFeatureById(features, 'keep_rmt');
  const lock_key = getFeatureById(features, 'lock_key');
  const audit_log = getFeatureById(features, 'audit_log');
  const pdf_ann = getFeatureById(features, 'pdf_ann');
  const pdf_edt = getFeatureById(features, 'pdf_edt');
  const share_wit = getFeatureById(features, 'share_wit');
  const hw_tkn = getFeatureById(features, 'hw_tkn');
  const nas_syn = getFeatureById(features, 'nas_syn');
  const vip_sup = getFeatureById(features, 'vip_sup');

  const access_dny_feat = isValueTrue(access_dny?.[valueId]);
  const prom_adm_feat = isValueTrue(prom_adm?.[valueId]);
  const block_usr_feat = isValueTrue(block_usr?.[valueId]);
  const power_usr_feat = isValueTrue(power_usr?.[valueId]);
  const guest_usr_feat = isValueTrue(guest_usr?.[valueId]);
  const allow_dev_feat = isValueTrue(allow_dev?.[valueId]);
  const paus_syn_feat = isValueTrue(paus_syn?.[valueId]);
  const wipe_rmt_feat = isValueTrue(wipe_rmt?.[valueId]);
  const perm_itm_feat = isValueTrue(perm_itm?.[valueId]);
  const keep_rmt_feat = isValueTrue(keep_rmt?.[valueId]);
  const lock_key_feat = isValueTrue(lock_key?.[valueId]);
  const audit_log_feat = isValueTrue(audit_log?.[valueId]);
  const pdf_ann_feat = isValueTrue(pdf_ann?.[valueId]);
  const pdf_edt_feat = isValueTrue(pdf_edt?.[valueId]);
  const share_wit_feat = isValueTrue(share_wit?.[valueId]);
  const hw_tkn_feat = isValueTrue(hw_tkn?.[valueId]);
  const nas_syn_feat = isValueTrue(nas_syn?.[valueId]);
  const vip_sup_feat = isValueTrue(vip_sup?.[valueId]);
  const activatedFeatures = [];

  if (planId === 'cluster') {
    if (access_dny_feat) activatedFeatures.push(access_dny);
    if (prom_adm_feat) activatedFeatures.push(prom_adm);
    if (block_usr_feat) activatedFeatures.push(block_usr);
    if (power_usr_feat) activatedFeatures.push(power_usr);
    if (guest_usr_feat) activatedFeatures.push(guest_usr);
    if (allow_dev_feat) activatedFeatures.push(allow_dev);
    if (paus_syn_feat) activatedFeatures.push(paus_syn);
    if (wipe_rmt_feat) activatedFeatures.push(wipe_rmt);
    if (perm_itm_feat) activatedFeatures.push(perm_itm);
    if (keep_rmt_feat) activatedFeatures.push(keep_rmt);
    if (lock_key_feat) activatedFeatures.push(lock_key);
    if (audit_log_feat) activatedFeatures.push(audit_log);
    if (pdf_ann_feat) activatedFeatures.push(pdf_ann);
    if (share_wit_feat) activatedFeatures.push(share_wit);
    if (hw_tkn_feat) activatedFeatures.push(hw_tkn);
    if (nas_syn_feat) activatedFeatures.push(nas_syn);
    if (pdf_edt_feat) activatedFeatures.push(pdf_edt);
    if (vip_sup_feat) activatedFeatures.push(vip_sup);
  } else if (planId === 'team') {
    if (power_usr_feat) activatedFeatures.push(power_usr);
    if (guest_usr_feat) activatedFeatures.push(guest_usr);
    if (paus_syn_feat) activatedFeatures.push(paus_syn);
    if (wipe_rmt_feat) activatedFeatures.push(wipe_rmt);
    if (keep_rmt_feat) activatedFeatures.push(keep_rmt);
    if (audit_log_feat) activatedFeatures.push(audit_log);
    if (pdf_ann_feat) activatedFeatures.push(pdf_ann);
    if (pdf_edt_feat) activatedFeatures.push(pdf_edt);
    if (share_wit_feat) activatedFeatures.push(share_wit);
    if (vip_sup_feat) activatedFeatures.push(vip_sup);
  } else if (planId === 'business') {
    if (pdf_edt_feat) activatedFeatures.push(pdf_edt);
  }
  return activatedFeatures;
};

export function formatNumber(num: string | number) {
  let numStr = String(num);
  if (numStr.includes('.')) {
    let [whole, decimal] = numStr.split('.');
    if (decimal.length >= 2) {
      return `${whole}.${decimal.slice(0, 2)}`;
    }
    if (decimal.length === 1) {
      return `${whole}.${decimal}0`;
    }
  }
  return `${numStr}.00`;
}

export const calculatePlan = (
  features: IPlanFeature[],
  index: number,
  id: string,
  smsPlans: ISMSPlan[],
  smsAddons: ISMSPlanDetail[],
  selectedPriceOption?: SelectedPriceOption,
) => {
  const selectedItem = smsAddons?.find((i) => i.plan_id === id);
  if (id === 'personal') {
    return {
      monthly_price: '0.00',
      yearly_price: '0.00',
    };
  }
  const correctPDFVal =
    selectedPriceOption?.[(index - 1) as 1 | 2 | 3]?.price ||
    (selectedPriceOption as unknown as PDFLicense)?.price ||
    '0';
  const pdfPrice = parseFloat(correctPDFVal);
  const currentPlan = smsPlans[index - 1];
  const currentPlanYearlyPrice = parseFloat(currentPlan.yearly_price);
  const currentPlanMonthlyFactor = parseFloat(currentPlan.monthly_factor);
  const pdfMonthlyPrice = pdfPrice * currentPlanMonthlyFactor;
  const currentPlanMonthlyPrice =
    currentPlanYearlyPrice * currentPlanMonthlyFactor;

  const activatedFeatures = getActivatedFeatures(features, index, id);
  const featuresToFilter = ['pdf_edt', 'vip_sup'];
  const correctFeatures = activatedFeatures.filter(
    (feature) => !featuresToFilter.includes(feature.detail_id),
  );
  const filteredFeatures = activatedFeatures.filter((feature) =>
    featuresToFilter.includes(feature.detail_id),
  );
  const hasPrioritySupport = filteredFeatures.some(
    (f) => f.detail_id === 'vip_sup',
  );
  const hasPdfEditor = filteredFeatures.some((f) => f.detail_id === 'pdf_edt');

  if (id === 'business') {
    let floatPrice = currentPlanMonthlyPrice;
    if (hasPdfEditor) {
      floatPrice += pdfMonthlyPrice;
    }

    const monthlyPrice = floatPrice;
    const yearlyPrice = floatPrice / currentPlanMonthlyFactor;

    return {
      monthly_price: formatNumber(monthlyPrice),
      yearly_price: formatNumber(yearlyPrice),
    };
  }

  if (!selectedItem) {
    return {
      monthly_price: '0.00',
      yearly_price: '0.00',
    };
  }

  const selectedItems = selectedItem.items.slice();
  selectedItems.unshift({
    number_of_addons: 0,
    cumulative_price: '0.00',
    next_item_price: '0.50',
  });

  const totalCorrectFeatures = correctFeatures.length || 0;
  const pricingBasedOnAllAddons = selectedItems.find(
    (i) => i.number_of_addons === totalCorrectFeatures,
  );
  if (!pricingBasedOnAllAddons) {
    return {
      monthly_price: '0.00',
      yearly_price: '0.00',
    };
  }

  const PRIORITY_SUPPORT_MULTIPLIER = parseFloat(currentPlan.support_factor);
  const newAddOnPricing = pricingBasedOnAllAddons.cumulative_price || '0.00';
  let floatPrice = currentPlanMonthlyPrice + parseFloat(newAddOnPricing);
  if (hasPdfEditor) {
    floatPrice += pdfMonthlyPrice;
  }
  if (hasPrioritySupport) {
    floatPrice += PRIORITY_SUPPORT_MULTIPLIER * floatPrice;
  }
  const monthlyPrice = floatPrice;
  const yearlyPrice = floatPrice / currentPlanMonthlyFactor;

  return {
    monthly_price: formatNumber(monthlyPrice),
    yearly_price: formatNumber(yearlyPrice),
  };
};

export const getIndexesOfFeature = (features: IPlanFeature[], id: string) => {
  let i1: number = 0;
  let i2: number = 0;
  let i3: number = -1;
  features.map((feature, idx1) =>
    feature?.details?.map((detail, idx2) => {
      if (detail?.detail_id === id) {
        i1 = idx1;
        i2 = idx2;
      } else if (detail?.details?.length) {
        detail?.details?.map((d: any, idx3: number) => {
          if (d?.detail_id === id) {
            i1 = idx1;
            i2 = idx2;
            i3 = idx3;
          }
        });
      }
    }),
  );
  return [i1, i2, i3];
};

export const calculateEnabledFeaturesAndPlan = (
  enabledFeatures: Array<keyof typeof testPlans.team>,
  features: { plan_features: IPlanFeature[]; pricing_plans: IPlan[] },
  id: string,
  smsPlans: ISMSPlan[] = DEFAULT_PLANS as ISMSPlan[],
  smsAddons: ISMSPlanDetail[] = SMS_PLANS_ADDONS as ISMSPlanDetail[],
) => {
  const tempFeatures = features.plan_features;
  const indexOfPlan = features.pricing_plans.findIndex(
    (plan) => plan.plan_id === id,
  );

  enabledFeatures.map((feature) => {
    const indexesOfItem = getIndexesOfFeature(tempFeatures, feature);
    const valueKey =
      `plan_${indexOfPlan + 1}_value` as keyof IPlanFeatureDetail;
    tempFeatures[indexesOfItem[0]].details[indexesOfItem[1]][valueKey] = true;
  });
  return calculatePlan(
    tempFeatures,
    (indexOfPlan + 1) as 1 | 2 | 3 | 4,
    id || 'personal',
    smsPlans,
    smsAddons,
  );
};

export const parseQuery = () => {
  const parsedString = window.location.search.substring(1).split('&');
  let parsedQuery: { [key: string]: string } = {};
  parsedString.map((item) => {
    const key = item.split('=')[0];
    let value = item.split('=')[1];
    if (key === 'email' || key?.toLowerCase?.().includes?.('email')) {
      try {
        value = decodeURIComponent(value);
      } catch (err) {
        value = value || '';
      }
    }
    parsedQuery = { ...parsedQuery, [`${key}`]: value };
  });
  return parsedQuery;
};

export const languageOptions = ['en', 'de', 'es', 'fr', 'it', 'pt', 'ru'];
export const ZOOM_FACTOR = 0.1;
export const sizes = { w: 20, h: 20, viewBox: '0 0 20 20' };

export const encodeQuery = async (body = {}) => {
  const res = await axios.post('/api/encode-query-params', body);
  return res?.data?.query || '';
};

export const decodeQuery = async (query: any) => {
  const res = await axios.post('/api/decode-query-params', { query });
  return res?.data || {};
};

export const validateToken = async (_token = '') => {
  let token: string | IToken = _token;
  try {
    token = JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
  if ((token as IToken)?.exp) {
    const { exp } = token as IToken;
    // @ts-ignore
    if (new Date() > exp * 1000) {
      return false;
    }
    try {
      return await validateUser(_token);
    } catch (err) {
      return false;
    }
  }
  return false;
};

export const parseLinkAndCodeFromEmail = (body = '') => {
  const res = {
    oneTimeCode: '',
    magicLink: '',
  };
  const regex = /(?:(?:https?|ftp):\/\/)?[\w/\-?=%.]+\.[\w/\-&?=%.]+/gim;
  const allMatchedURLs = body.match(regex);
  if (allMatchedURLs?.length) {
    const magicLinkURL = allMatchedURLs
      .filter(
        (url) =>
          url?.toLowerCase?.()?.includes?.('.eisst.com') ||
          url?.toLowerCase?.()?.includes?.('.quodarca.net') ||
          url?.toLowerCase?.()?.includes?.('.quodarca.com'),
      )?.[0]
      ?.split('&amp')
      ?.join('');
    if (magicLinkURL?.length) {
      const indexOfURL = body.indexOf(magicLinkURL);
      const indexOfSpaceAfterURL = body.indexOf(' ', indexOfURL);
      const fullLink = body.substring(indexOfURL, indexOfSpaceAfterURL);
      if (fullLink.match(/\d{6}/)) {
        res.oneTimeCode = fullLink.match(/\d{6}/)?.[0] || '';
      } else {
        res.oneTimeCode = fullLink.split('token=')[1]?.split?.(`"`)?.join?.('');
      }
      res.magicLink = fullLink
        .split('&amp;')
        .join('&')
        .split?.(`"`)
        ?.join?.('');
    }
  }
  return res;
};

export function extractHttpsLinks(str: string) {
  const regex = /https:\/\/[a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=%]+/g;
  const matches = str.match(regex)?.map((res) => res.split(')').join(''));
  return matches || [];
}

export const matchTokens = (body = '', text = '', html = '') => {
  const regex = /^[a-zA-Z0-9_-]+$/;
  if (regex.test(text)) {
    return text;
  }
  if (regex.test(html)) {
    return html;
  }
  if (regex.test(body)) {
    return body;
  }
  const regex2 = /data-code="(\d+)"|data-code="([a-zA-Z0-9_-]+)"/;
  const match4 = body.match(regex2);
  const match5 = text.match(regex2);
  const match6 = html.match(regex2);
  if (match4 && match4.length >= 3) {
    return match4[1] || match4[2];
  }
  if (match5 && match5.length >= 3) {
    return match5[1] || match5[2];
  }
  if (match6 && match6.length >= 3) {
    return match6[1] || match6[2];
  }
  if (regex.test(html.replace(/<\/?p>/gi, ''))) {
    return html.replace(/<\/?p>/gi, '');
  }
  const regex1 = />(\d+)<\/div>/;
  const match1 = body.match(regex1);
  const match2 = text.match(regex1);
  const match3 = html.match(regex1);
  if (match1 && match1.length >= 2) {
    return match1[1];
  }
  if (match2 && match2.length >= 2) {
    return match2[1];
  }
  if (match3 && match3.length >= 2) {
    return match3[1];
  }

  return '';
};

export const matchRestOfElements = (_text = '') => {
  let text = _text;
  const regex = /(\w+)=([^=\n]+)/g;
  const result: any = {};

  let match;
  let i = 0;
  while ((match = regex.exec(text))) {
    result[match[1]] = decodeURIComponent(match[2].split('\r').join(''));
    i += 1;
    if (i > 100) {
      break;
    }
  }
  return result;
};

export const parseLinkAndTokenFromEmail = (
  _body = '',
  _text = '',
  _html = '',
  subject = '',
) => {
  const res = {
    oneTimeCode: '',
    magicLink: '',
    token: '',
  };
  let body = _body.split('&amp;').join('&');
  let html = _html.split('&amp;').join('&');
  const allMatchedURLs = extractHttpsLinks(html);
  const allMatchedTokens = matchTokens(body, _text, _html);
  const restMatches = matchRestOfElements(_text);
  if (allMatchedURLs?.length) {
    const magicLinkURLs = allMatchedURLs.filter((url) => {
      let lowerCaseURL = url?.toLowerCase?.();
      return (
        !lowerCaseURL?.includes('ios-and-android') &&
        !lowerCaseURL?.includes('digitalocean') &&
        !lowerCaseURL?.includes('mac-and-windows') &&
        (lowerCaseURL?.includes('token=') ||
          lowerCaseURL?.includes('code=') ||
          lowerCaseURL?.includes?.('.quodarca.com') ||
          lowerCaseURL?.includes?.('.quodarca.net') ||
          lowerCaseURL?.includes?.('.eisst.com'))
      );
    });
    magicLinkURLs.sort((a, b) => {
      if (a?.toLowerCase?.()?.includes?.('backend.quodarca.net')) {
        return -1;
      }
      if (b?.toLowerCase?.()?.includes?.('backend.quodarca.net')) {
        return 1;
      }
      return 0;
    });
    magicLinkURLs.sort((a, b) => {
      if (a?.toLowerCase?.()?.includes?.('token=')) {
        return -1;
      }
      if (b?.toLowerCase?.()?.includes?.('token=')) {
        return 1;
      }
      return 0;
    });
    magicLinkURLs.sort((a, b) => {
      if (a?.toLowerCase?.()?.includes?.('action/')) {
        return -1;
      }
      if (b?.toLowerCase?.()?.includes?.('action/')) {
        return 1;
      }
      return 0;
    });
    const magicLinkURL = magicLinkURLs?.[0]?.split('&amp')?.join('');
    if (magicLinkURL?.length) {
      const indexOfURL = body.indexOf(magicLinkURL);
      let indexOfSpaceAfterURL = body.indexOf(')', indexOfURL);
      if (indexOfSpaceAfterURL === -1) {
        indexOfSpaceAfterURL = body.indexOf(' ', indexOfURL);
      }
      const fullLink = body.substring(indexOfURL, indexOfSpaceAfterURL);
      if (fullLink.match(/\d{6}/)) {
        res.oneTimeCode = fullLink.match(/\d{6}/)?.[0] || '';
      } else {
        res.oneTimeCode = fullLink.split('token=')[1]?.split?.(`"`)?.join?.('');
        res.token = res.oneTimeCode;
      }
      res.magicLink = magicLinkURL;
    }
  }
  if (allMatchedTokens?.length && !res.oneTimeCode) {
    res.oneTimeCode = allMatchedTokens;
  }
  if (restMatches?.token && !res.oneTimeCode) {
    res.oneTimeCode = restMatches.token;
  }
  if (restMatches?.magicLink && !res.magicLink) {
    res.magicLink = restMatches.magicLink;
  }
  const verificationCodeMatch = _text.match(/\b\d{6}\b/);
  if (verificationCodeMatch && !res.token) {
    res.oneTimeCode = verificationCodeMatch[0];
  }

  const verificationCodeMatch2 = _text.match(
    /(?<=Verification Code:\r\n\s*)\b\d{6}\b/,
  );
  if (verificationCodeMatch2) {
    res.oneTimeCode = verificationCodeMatch2[0];
  }

  const oneTimeCodeRegex =
    /<div style="margin-top: 8px; text-align: center; font-size: 30px; font-weight: 700; line-height: 40px; color: #030303; font-family: Arial, Helvetica, sans-serif">(\d{6})<\/div>/;
  const oneTimeCodeMatch = body.match(oneTimeCodeRegex);
  if (oneTimeCodeMatch) {
    res.oneTimeCode = oneTimeCodeMatch[1];
  }

  const specificLinkRegex =
    /https:\/\/website\.quodarca\.com\/sms\/welcome-login\.html\?token=\d+&email=[^"]+/;
  const specificLinkMatch = body.match(specificLinkRegex);
  if (specificLinkMatch) {
    res.magicLink = specificLinkMatch[0];
  }
  const alphanumericRegex = /<strong>([a-zA-Z0-9]{7,})<\/strong>/;
  const alphanumericMatch = _html.match(alphanumericRegex);
  if (alphanumericMatch) {
    res.token = alphanumericMatch[1];
  }

  const allMatchedTokens2 = matchTokens(body, _text, _html);

  if (allMatchedTokens2?.length) {
    res.oneTimeCode = allMatchedTokens2;
    if (!res.token) {
      res.token = allMatchedTokens2;
    }
  }

  const invitationCodeRegex =
    /id="invitation-code" data-invitation-code="(\d{6})"/;
  const invitationCodeMatch = _html.match(invitationCodeRegex);
  if (invitationCodeMatch) {
    res.oneTimeCode = invitationCodeMatch[1];
  }

  const unlockCodeRegexWithStyle =
    /<span style="color:unlock_authcode;"><strong>(\d{6})<\/strong><\/span>/;
  const unlockCodeMatchWithStyle = _html.match(unlockCodeRegexWithStyle);
  if (unlockCodeMatchWithStyle?.[1]) {
    res.oneTimeCode = unlockCodeMatchWithStyle[1];
  } else if (subject === 'QuodArca unlock request') {
    const unlockCodeRegex = /Unlock Code:\r\n\s*(\d{6})/;
    const unlockCodeMatch = _text.match(unlockCodeRegex);
    if (unlockCodeMatch?.[1]) {
      res.oneTimeCode = unlockCodeMatch[1];
    }
  }

  return res;
};

export const parseDeepLinkFromEmail = (body = '') => {
  const res = {
    magicLink: '',
  };
  const regex = /(?:(?:https?|ftp):\/\/)?[\w/\-?=%.]+\.[\w/\-&?=%.]+/gim;
  const allMatchedURLs = body.match(regex);
  if (allMatchedURLs?.length) {
    const magicLinkURL = allMatchedURLs
      .filter((url) => url.toLowerCase().includes('backend.'))[0]
      .split('&amp')
      .join('');
    if (magicLinkURL.length) {
      const indexOfURL = body.indexOf(magicLinkURL);
      const indexOfSpaceAfterURL = body.indexOf(' ', indexOfURL);
      const fullLink = body.substring(indexOfURL, indexOfSpaceAfterURL);
      res.magicLink = fullLink
        .split('&amp;')
        .join('&')
        .split?.(`"`)
        ?.join?.('');
    }
  }
  return res;
};

export const getTokenFromEmail = async (email = '') => {
  let out = {
    oneTimeCode: '',
    magicLink: '',
  };
  const login = email.split('@')[0];
  const domain = email.split('@')[1];
  const res = await axios
    .get(
      `https://www.1secmail.com/api/v1` +
        `/?action=getMessages&login=${login}&domain=${domain}`,
    )
    .then((response) => response.data)
    .catch((err) => err);
  if (res?.length) {
    const res2 = await axios
      .get(
        `https://www.1secmail.com/api/v1` +
          `/?action=readMessage&login=${login}&domain=${domain}&id=${res?.[0]?.id}`,
      )
      .then((response) => response.data)
      .catch((err) => err);
    out = parseLinkAndCodeFromEmail(res2.body);
  }
  return out;
};

export const getLinkFromMail = async (email = '') => {
  let out = {
    magicLink: '',
  };
  const login = email.split('@')[0];
  const domain = email.split('@')[1];
  const res = await axios
    .get(
      `https://www.1secmail.com/api/v1` +
        `/?action=getMessages&login=${login}&domain=${domain}`,
    )
    .then((response) => response.data)
    .catch((err) => err);
  if (res?.length) {
    const res2 = await axios
      .get(
        `https://www.1secmail.com/api/v1` +
          `/?action=readMessage&login=${login}&domain=${domain}&id=${res?.[0]?.id}`,
      )
      .then((response) => response.data)
      .catch((err) => err);
    out = parseDeepLinkFromEmail(res2.body);
  }
  return out.magicLink;
};

export const trimString = (str: string) =>
  str.trim().toLowerCase().split('-').join('').split('_').join('');

export const wait = (ms: number, value?: any) =>
  new Promise((resolve) => setTimeout(resolve, ms, value));

export const toBoolean = (value: boolean | string | unknown = 'false') => {
  try {
    if (value === 'coming_soon') return false;
    if (value === 'contact_sales') return false;
    if (value === true || value === false) {
      return value;
    }
    if (typeof value === 'string') return value?.toLowerCase?.() === 'true';

    return (value as string)?.toString?.().toLowerCase?.() === 'true';
  } catch (err) {
    return false;
  }
};

export const getFullPlanDetails = (
  plan: any,
  currentFeatures: IPlanFeature[],
  pricing_plans: any[],
  recursion: string,
  custom = false,
  locale = 'en',
) => {
  try {
    const fullPlan = pricing_plans.filter(
      (p: any) => p.plan_id === plan.plan_id,
    )[0];
    const idx = pricing_plans.indexOf(fullPlan) + 1 || 2;
    const role_mnt_feat = getFeatureById(currentFeatures, 'role_mnt')[
      `plan_${idx}_value`
    ];
    const access_dny_feat = getFeatureById(currentFeatures, 'access_dny')[
      `plan_${idx}_value`
    ];
    const prom_adm_feat = getFeatureById(currentFeatures, 'prom_adm')[
      `plan_${idx}_value`
    ];
    const block_usr_feat = getFeatureById(currentFeatures, 'block_usr')[
      `plan_${idx}_value`
    ];
    const power_usr_feat = getFeatureById(currentFeatures, 'power_usr')[
      `plan_${idx}_value`
    ];
    const guest_usr_feat = getFeatureById(currentFeatures, 'guest_usr')[
      `plan_${idx}_value`
    ];
    const allow_dev_feat = getFeatureById(currentFeatures, 'allow_dev')[
      `plan_${idx}_value`
    ];
    const paus_syn_feat = getFeatureById(currentFeatures, 'paus_syn')[
      `plan_${idx}_value`
    ];
    const wipe_rmt_feat = getFeatureById(currentFeatures, 'wipe_rmt')[
      `plan_${idx}_value`
    ];
    const role_ctrl_feat = getFeatureById(currentFeatures, 'role_ctrl')[
      `plan_${idx}_value`
    ];
    const perm_itm_feat = getFeatureById(currentFeatures, 'perm_itm')[
      `plan_${idx}_value`
    ];
    const keep_rmt_feat = getFeatureById(currentFeatures, 'keep_rmt')[
      `plan_${idx}_value`
    ];
    const lock_key_feat = getFeatureById(currentFeatures, 'lock_key')[
      `plan_${idx}_value`
    ];
    const audit_log_feat = getFeatureById(currentFeatures, 'audit_log')[
      `plan_${idx}_value`
    ];
    const pdf_ann_feat = getFeatureById(currentFeatures, 'pdf_ann')[
      `plan_${idx}_value`
    ];
    const pdf_edt_feat = getFeatureById(currentFeatures, 'pdf_edt')[
      `plan_${idx}_value`
    ];
    const pdf_sig_feat = getFeatureById(currentFeatures, 'pdf_sig')[
      `plan_${idx}_value`
    ];
    const share_wit_feat = getFeatureById(currentFeatures, 'share_wit')[
      `plan_${idx}_value`
    ];
    const hw_tkn_feat = getFeatureById(currentFeatures, 'hw_tkn')[
      `plan_${idx}_value`
    ];
    const nas_syn_feat = getFeatureById(currentFeatures, 'nas_syn')[
      `plan_${idx}_value`
    ];
    const vip_sup_feat = getFeatureById(currentFeatures, 'vip_sup')[
      `plan_${idx}_value`
    ];
    const inv_usr_feat = getFeatureById(currentFeatures, 'inv_usr')[
      `plan_${idx}_value`
    ];
    const del_usr_feat = getFeatureById(currentFeatures, 'del_usr')[
      `plan_${idx}_value`
    ];
    const activ_dev_feat = getFeatureById(currentFeatures, 'activ_dev')[
      `plan_${idx}_value`
    ];

    return {
      plan_id: plan.plan_id,
      recursion,
      price: recursion === 'monthly' ? plan.monthly_price : plan.yearly_price,
      other_price:
        recursion === 'monthly' ? plan.yearly_price : plan.monthly_price,
      custom,
      locale,
      role_mnt: isValueTrue(role_mnt_feat),
      access_dny: isValueTrue(access_dny_feat),
      prom_adm: isValueTrue(prom_adm_feat),
      block_usr: isValueTrue(block_usr_feat),
      power_usr: isValueTrue(power_usr_feat),
      guest_usr: isValueTrue(guest_usr_feat),
      allow_dev: isValueTrue(allow_dev_feat),
      paus_syn: isValueTrue(paus_syn_feat),
      wipe_rmt: isValueTrue(wipe_rmt_feat),
      role_ctrl: isValueTrue(role_ctrl_feat),
      perm_itm: isValueTrue(perm_itm_feat),
      keep_rmt: isValueTrue(keep_rmt_feat),
      lock_key: isValueTrue(lock_key_feat),
      audit_log: isValueTrue(audit_log_feat),
      pdf_ann: isValueTrue(pdf_ann_feat),
      pdf_sig: isValueTrue(pdf_sig_feat),
      share_wit: isValueTrue(share_wit_feat),
      hw_tkn: isValueTrue(hw_tkn_feat),
      nas_syn: isValueTrue(nas_syn_feat),
      vip_sup: isValueTrue(vip_sup_feat),
      inv_usr: isValueTrue(inv_usr_feat),
      del_usr: isValueTrue(del_usr_feat),
      activ_dev: isValueTrue(activ_dev_feat),
      ...(pdf_edt_feat ? { pdf_edt: isValueTrue(pdf_edt_feat) } : {}),
    };
  } catch (err) {
    return false;
  }
};

export const enableFeaturesForSurrondingPlans = (
  plansFeatures: IPlanFeature[],
  previouslySelectedPlan: number,
  newSelectedPlan: number,
) => {
  if (!plansFeatures?.length || previouslySelectedPlan < 0) {
    return null;
  }

  const allFeatures = [
    getFeatureById(plansFeatures, 'role_mnt'),
    getFeatureById(plansFeatures, 'access_dny'),
    getFeatureById(plansFeatures, 'prom_adm'),
    getFeatureById(plansFeatures, 'block_usr'),
    getFeatureById(plansFeatures, 'power_usr'),
    getFeatureById(plansFeatures, 'guest_usr'),
    getFeatureById(plansFeatures, 'allow_dev'),
    getFeatureById(plansFeatures, 'paus_syn'),
    getFeatureById(plansFeatures, 'wipe_rmt'),
    getFeatureById(plansFeatures, 'role_ctrl'),
    getFeatureById(plansFeatures, 'perm_itm'),
    getFeatureById(plansFeatures, 'keep_rmt'),
    getFeatureById(plansFeatures, 'lock_key'),
    getFeatureById(plansFeatures, 'audit_log'),
    getFeatureById(plansFeatures, 'pdf_ann'),
    getFeatureById(plansFeatures, 'pdf_edt'),
    getFeatureById(plansFeatures, 'share_wit'),
    getFeatureById(plansFeatures, 'hw_tkn'),
    getFeatureById(plansFeatures, 'nas_syn'),
    getFeatureById(plansFeatures, 'vip_sup'),
  ];
  const tempCopy = JSON.parse(
    JSON.stringify([...plansFeatures]),
  ) as IPlanFeature[];
  allFeatures.map((feature) => {
    const valueKey =
      `plan_${previouslySelectedPlan + 1}_value` as keyof IPlanFeatureDetail;
    const indexesOfFeature = getIndexesOfFeature(
      plansFeatures,
      feature.detail_id,
    );
    const valueOfFeature = feature[valueKey];
    if (isValueTrue(valueOfFeature)) {
      const valueKey2 =
        `plan_${newSelectedPlan}_value` as keyof IPlanFeatureDetail;
      const isEditableKey =
        `is_editable_for_plan_${newSelectedPlan}` as keyof IPlanFeatureDetail;

      let selectedElement =
        tempCopy[indexesOfFeature[0]].details[indexesOfFeature[1]];
      if (indexesOfFeature?.[2] > -1) {
        selectedElement =
          tempCopy[indexesOfFeature[0]].details[indexesOfFeature[1]].details[
            indexesOfFeature[2]
          ];
      }
      const isEditable = selectedElement[isEditableKey];
      if (isEditable) {
        if (indexesOfFeature?.[2] > -1) {
          tempCopy[indexesOfFeature[0]].details[indexesOfFeature[1]].details[
            indexesOfFeature[2]
          ][valueKey2] =
            tempCopy[indexesOfFeature[0]].details[indexesOfFeature[1]].details[
              indexesOfFeature[2]
            ][valueKey];
        } else {
          tempCopy[indexesOfFeature[0]].details[indexesOfFeature[1]][
            valueKey2
          ] =
            tempCopy[indexesOfFeature[0]].details[indexesOfFeature[1]][
              valueKey
            ];
        }
      }
    }
  });
  return tempCopy;
};

export const getFullDefaultPlanDetails = async (plan: any) => {
  try {
    const { plan_features: currentFeatures, pricing_plans } = await axios
      .post('/api/pricing-table', {
        locale: 'en',
      })
      .then((res) => res.data)
      .catch((err) => err);
    const fullPlan = pricing_plans.filter(
      (p: any) => p.plan_id === plan.plan_id,
    )[0];
    const idx = pricing_plans.indexOf(fullPlan) + 1 || 2;
    const role_mnt_feat = getFeatureById(currentFeatures, 'role_mnt')[
      `plan_${idx}_value`
    ];
    const access_dny_feat = getFeatureById(currentFeatures, 'access_dny')[
      `plan_${idx}_value`
    ];
    const prom_adm_feat = getFeatureById(currentFeatures, 'prom_adm')[
      `plan_${idx}_value`
    ];
    const block_usr_feat = getFeatureById(currentFeatures, 'block_usr')[
      `plan_${idx}_value`
    ];
    const power_usr_feat = getFeatureById(currentFeatures, 'power_usr')[
      `plan_${idx}_value`
    ];
    const guest_usr_feat = getFeatureById(currentFeatures, 'guest_usr')[
      `plan_${idx}_value`
    ];
    const allow_dev_feat = getFeatureById(currentFeatures, 'allow_dev')[
      `plan_${idx}_value`
    ];
    const paus_syn_feat = getFeatureById(currentFeatures, 'paus_syn')[
      `plan_${idx}_value`
    ];
    const wipe_rmt_feat = getFeatureById(currentFeatures, 'wipe_rmt')[
      `plan_${idx}_value`
    ];
    const role_ctrl_feat = getFeatureById(currentFeatures, 'role_ctrl')[
      `plan_${idx}_value`
    ];
    const perm_itm_feat = getFeatureById(currentFeatures, 'perm_itm')[
      `plan_${idx}_value`
    ];
    const keep_rmt_feat = getFeatureById(currentFeatures, 'keep_rmt')[
      `plan_${idx}_value`
    ];
    const lock_key_feat = getFeatureById(currentFeatures, 'lock_key')[
      `plan_${idx}_value`
    ];
    const audit_log_feat = getFeatureById(currentFeatures, 'audit_log')[
      `plan_${idx}_value`
    ];
    const pdf_ann_feat = getFeatureById(currentFeatures, 'pdf_ann')[
      `plan_${idx}_value`
    ];
    const pdf_edt_feat = getFeatureById(currentFeatures, 'pdf_edt')[
      `plan_${idx}_value`
    ];
    const pdf_sig_feat = getFeatureById(currentFeatures, 'pdf_sig')[
      `plan_${idx}_value`
    ];
    const share_wit_feat = getFeatureById(currentFeatures, 'share_wit')[
      `plan_${idx}_value`
    ];
    const hw_tkn_feat = getFeatureById(currentFeatures, 'hw_tkn')[
      `plan_${idx}_value`
    ];
    const nas_syn_feat = getFeatureById(currentFeatures, 'nas_syn')[
      `plan_${idx}_value`
    ];
    const vip_sup_feat = getFeatureById(currentFeatures, 'vip_sup')[
      `plan_${idx}_value`
    ];
    const inv_usr_feat = getFeatureById(currentFeatures, 'inv_usr')[
      `plan_${idx}_value`
    ];
    const del_usr_feat = getFeatureById(currentFeatures, 'del_usr')[
      `plan_${idx}_value`
    ];
    const activ_dev_feat = getFeatureById(currentFeatures, 'activ_dev')[
      `plan_${idx}_value`
    ];
    const role_mnt = plan.role_mnt || role_mnt_feat;
    const access_dny = plan.access_dny || access_dny_feat;
    const prom_adm = plan.prom_adm || prom_adm_feat;
    const block_usr = plan.block_usr || block_usr_feat;
    const power_usr = plan.power_usr || power_usr_feat;
    const guest_usr = plan.guest_usr || guest_usr_feat;
    const allow_dev = plan.allow_dev || allow_dev_feat;
    const paus_syn = plan.paus_syn || paus_syn_feat;
    const wipe_rmt = plan.wipe_rmt || wipe_rmt_feat;
    const role_ctrl = plan.role_ctrl || role_ctrl_feat;
    const perm_itm = plan.perm_itm || perm_itm_feat;
    const keep_rmt = plan.keep_rmt || keep_rmt_feat;
    const lock_key = plan.lock_key || lock_key_feat;
    const audit_log = plan.audit_log || audit_log_feat;
    const pdf_ann = plan.pdf_ann || pdf_ann_feat;
    const pdf_edt = plan.pdf_edt || pdf_edt_feat;
    const pdf_sig = plan.pdf_sig || pdf_sig_feat;
    const share_wit = plan.share_wit || share_wit_feat;
    const hw_tkn = plan.hw_tkn || hw_tkn_feat;
    const nas_syn = plan.nas_syn || nas_syn_feat;
    const vip_sup = plan.vip_sup || vip_sup_feat;
    const inv_usr = plan.inv_usr || inv_usr_feat;
    const del_usr = plan.del_usr || del_usr_feat;
    const activ_dev = plan.activ_dev || activ_dev_feat;
    return {
      ...plan,
      role_mnt: isValueTrue(role_mnt),
      access_dny: isValueTrue(access_dny),
      prom_adm: isValueTrue(prom_adm),
      block_usr: isValueTrue(block_usr),
      power_usr: isValueTrue(power_usr),
      guest_usr: isValueTrue(guest_usr),
      allow_dev: isValueTrue(allow_dev),
      paus_syn: isValueTrue(paus_syn),
      wipe_rmt: isValueTrue(wipe_rmt),
      role_ctrl: isValueTrue(role_ctrl),
      perm_itm: isValueTrue(perm_itm),
      keep_rmt: isValueTrue(keep_rmt),
      lock_key: isValueTrue(lock_key),
      audit_log: isValueTrue(audit_log),
      pdf_ann: isValueTrue(pdf_ann),
      pdf_sig: isValueTrue(pdf_sig),
      share_wit: isValueTrue(share_wit),
      hw_tkn: isValueTrue(hw_tkn),
      nas_syn: isValueTrue(nas_syn),
      vip_sup: isValueTrue(vip_sup),
      inv_usr: isValueTrue(inv_usr),
      del_usr: isValueTrue(del_usr),
      activ_dev: isValueTrue(activ_dev),
      ...(isValueTrue(pdf_edt) ? { pdf_edt } : {}),
    };
  } catch (err) {
    return null;
  }
};

export function parseDetails(htmlString: string): ParsedDetails {
  const decodedHtmlString = quotedPrintable.decode(htmlString);
  const $ = cheerio.load(decodedHtmlString);

  let details: ParsedDetails = {};

  function extractDetails(text: string) {
    try {
      let splitText = text.split(':').slice(1).join(':').trim();
      if (text.toLowerCase().includes('time')) {
        details.time = splitText;
        details.encodedTime = encodeURIComponent(splitText);
      } else if (text.toLowerCase().includes('location')) {
        details.location = splitText;
      } else if (
        text.toLowerCase().includes('device') &&
        !text.toLowerCase().includes('device types allowed') &&
        !text.toLowerCase().includes('device allowance limit')
      ) {
        details.device = splitText;
      } else if (text.toLowerCase().includes('ip address')) {
        details.ipAddress = splitText;
      } else if (text.toLowerCase().includes('device types allowed')) {
        details.deviceTypesAllowed = splitText;
      } else if (text.toLowerCase().includes('device allowance limit')) {
        details.deviceAllowanceLimit = splitText;
      } else if (text.toLowerCase().includes('subscription')) {
        details.subscription = splitText;
      } else if (text.toLowerCase().includes('quodArca account owner')) {
        details.accountOwner = splitText;
      } else if (text.toLowerCase().includes('browser')) {
        details.browser = splitText;
      } else if (text.toLowerCase().includes('email of the invitee')) {
        details.inviteeEmail = splitText;
      } else if (text.toLowerCase().includes('previous email')) {
        details.previousEmail = splitText;
      } else if (text.toLowerCase().includes('new email')) {
        details.newEmail = splitText;
      } else if (text.toLowerCase().includes('email')) {
        details.email = splitText;
      }

      if (!details.subscription) {
        const regex = /associated with your <strong>([^<]+)<\/strong>/;
        const match = htmlString.match(regex);
        if (match && match.length >= 2) {
          details.subscription = match[1];
        }
      }
      const regex2 =
        /QuodArca Account Owner with the email address <strong>([^<]+)<\/strong>/;
      const match2 = htmlString.match(regex2);
      if (match2 && match2.length >= 2 && !details.qaoEmail) {
        if (match2[1].includes('@')) details.qaoEmail = match2[1];
      }
      const regex3 = /<strong>([^<]+)<\/strong> as QuodArca® Account Owner/;
      const match3 = htmlString.match(regex3);
      if (match3 && match3.length >= 2 && !details.qaoEmail) {
        if (match3[1].includes('@')) details.qaoEmail = match3[1];
      }
      const regex4 =
        /informed regarding your <strong>([^<]+)<\/strong> subscription/;
      const match4 = htmlString.match(regex4);
      if (match4 && match4.length >= 2 && !details.subscription) {
        details.subscription = match4[1];
      }
      const regex5 =
        /your inviter at <strong>([^<]+)<\/strong> before proceeding/;
      const match5 = htmlString.match(regex5);
      if (match5 && match5.length >= 2 && !details.qaoEmail) {
        if (match5[1].includes('@')) details.qaoEmail = match5[1];
      }
      const regex6 = /your invitation to <strong>([^<]+)<\/strong> to join/;
      const match6 = htmlString.match(regex6);
      if (match6 && match6.length >= 2 && !details.inviteeEmail) {
        if (match6[1].includes('@')) details.inviteeEmail = match6[1];
      }
      const regex7 = /Member from <strong>([^<]+)<\/strong> has been/;
      const match7 = htmlString.match(regex7);
      if (match7 && match7.length >= 2 && !details.qaoEmail) {
        if (match7[1].includes('@')) details.qaoEmail = match7[1];
      }
      const regex8 =
        /associated with the <strong>([^<]+)<\/strong> QuodArca® account/;
      const match8 = htmlString.match(regex8);
      if (match8 && match8.length >= 2 && !details.accountName) {
        details.accountName = match8[1];
      }
      const regex9 = /the <strong>([^<]+)<\/strong> QuodArca® account/;
      const match9 = htmlString.match(regex9);
      if (match9 && match9.length >= 2 && !details.accountName) {
        details.accountName = match9[1];
      }
      const regex10 = /<strong>([^<]+)<\/strong>, whom you previously invited,/;
      const match10 = htmlString.match(regex10);
      if (match10 && match10.length >= 2 && !details.inviteeEmail) {
        if (match10[1].includes('@')) details.inviteeEmail = match10[1];
      }
      const regex11 = /join the <strong>([^<]+)<\/strong> QuodArca® account/;
      const match11 = htmlString.match(regex11);
      if (match11 && match11.length >= 2 && !details.accountName) {
        details.accountName = match11[1];
      }
      const regex12 =
        /sync and become a <strong>([^<]+)<\/strong> of the account/;
      const match12 = htmlString.match(regex12);
      if (match12 && match12.length >= 2 && !details.userType) {
        details.userType = match12[1];
      }
      const regex13 =
        /registered with the <strong>([^<]+)<\/strong> QuodArca® account/;
      const match13 = htmlString.match(regex13);
      if (match13 && match13.length >= 2 && !details.accountName) {
        details.accountName = match13[1];
      }
      const regex14 = /deactivated your device at <strong>([^<]+)<\/strong>/;
      const match14 = htmlString.match(regex14);
      if (match14 && match14.length >= 2 && !details.qaoEmail) {
        if (match14[1].includes('@')) details.qaoEmail = match14[1];
      }
      const regex15 =
        /associated with the <strong>([^<]+)<\/strong> QuodArca® account/;
      const match15 = htmlString.match(regex15);
      if (match15 && match15.length >= 2 && !details.accountName) {
        details.accountName = match15[1];
      }
      const regex16 = /device's status at <strong>([^<]+)<\/strong>/;
      const match16 = htmlString.match(regex16);
      if (match16 && match16.length >= 2 && !details.qaoEmail) {
        if (match16[1].includes('@')) details.qaoEmail = match16[1];
      }
      const regex17 = /access the <strong>([^<]+)<\/strong> QuodArca® account/;
      const match17 = htmlString.match(regex17);
      if (match17 && match17.length >= 2 && !details.accountName) {
        details.accountName = match17[1];
      }
      const regex18 =
        /activity for the <strong>([^<]+)<\/strong> QuodArca® account/;
      const match18 = htmlString.match(regex18);
      if (match18 && match18.length >= 2 && !details.accountName) {
        details.accountName = match18[1];
      }
    } catch (err) {
      /* empty */
    }
  }

  $('ul li div').each((index, element) => {
    extractDetails($(element).text());
  });

  $('.green-links')
    .children('div')
    .children('p')
    .each((index, element) => {
      extractDetails($(element).text());
    });

  $('.green-links')
    .children('p')
    .each((index, element) => {
      extractDetails($(element).text());
    });

  $('#reviewDetails')
    .children('p')
    .each((index, element) => {
      extractDetails($(element).text());
    });

  return details;
}

export function deepEqual(array1: any[], array2: any[]): boolean {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i += 1) {
    const obj1 = array1[i];
    const obj2 = array2[i];

    const keys1 = Object.keys(obj1 || {});
    const keys2 = Object.keys(obj2 || {});

    if (keys1.length !== keys2.length) {
      return false;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (let key of keys1) {
      if (key === 'details') {
        if (!deepEqual(obj1[key], obj2[key])) {
          return false;
        }
      } else if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  }
  return true;
}

export const log = (message: string, additionalInfo?: any) => {
  Logger.log(message, additionalInfo);
};

export const warn = (message: string, additionalInfo?: any) => {
  Logger.warn(message, additionalInfo);
};

export const error = (message: string, additionalInfo?: any) => {
  Logger.error(message, additionalInfo);
};

export const getFileFromStrapi = (key = '') =>
  axios
    .get(`/api/file/${key}`)
    .then((res) => res.data)
    .catch((err) => err);
export const stripHTMLTags = (input = '') =>
  input.replace(/<\/?[^>]+(>|$)/g, '');

export const blobToBase64 = (blob: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });

export const tutorialInteraction = async (
  slug: string,
  reaction: 'like' | 'dislike',
) => {
  try {
    const data = {
      url: window.location.href,
      urlref: document.referrer,
      res: `${window.screen.width}x${window.screen.height}`,
      h: new Date().getHours(),
      m: new Date().getMinutes(),
      s: new Date().getSeconds(),
      slug,
      reaction,
    };

    await axios
      .post('/api/analytics', data)
      .then((res) => res)
      .catch((err) => err);
  } catch (err) {
    /* empty */
  }
};

export const showToast = {
  info: (message: string) => {
    toast.info(message, {
      position: 'top-right',
      autoClose: 5000,
      style: {
        zIndex: 999999999999,
      },
    });
  },
  success: (message: string) => {
    toast.success(message, {
      position: 'top-right',
      autoClose: 5000,
      style: {
        zIndex: 999999999999,
      },
    });
  },
  error: (message: string) => {
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      style: {
        zIndex: 9999999,
      },
    });
  },
};

export const decodeURIComponentSafe = (str: string) => {
  try {
    return decodeURIComponent(str);
  } catch (err) {
    return str;
  }
};

export const encodeURIComponentSafe = (str: string) => {
  try {
    return encodeURIComponent(str);
  } catch (err) {
    return str;
  }
};

export const preparePayloadForFreePlan = (
  data: any,
  locale: string,
  email: string,
  currency: any,
) => {
  const selectedPlan = data?.pricing_plans?.[0];
  const selectedDetail = data?.plan_features;
  const { plan_id } = selectedPlan;
  const role_mnt_feat = getFeatureById(selectedDetail, 'role_mnt');
  const access_dny_feat = getFeatureById(selectedDetail, 'access_dny');
  const prom_adm_feat = getFeatureById(selectedDetail, 'prom_adm');
  const block_usr_feat = getFeatureById(selectedDetail, 'block_usr');
  const power_usr_feat = getFeatureById(selectedDetail, 'power_usr');
  const guest_usr_feat = getFeatureById(selectedDetail, 'guest_usr');
  const allow_dev_feat = getFeatureById(selectedDetail, 'allow_dev');
  const paus_syn_feat = getFeatureById(selectedDetail, 'paus_syn');
  const wipe_rmt_feat = getFeatureById(selectedDetail, 'wipe_rmt');
  const role_ctrl_feat = getFeatureById(selectedDetail, 'role_ctrl');
  const perm_itm_feat = getFeatureById(selectedDetail, 'perm_itm');
  const keep_rmt_feat = getFeatureById(selectedDetail, 'keep_rmt');
  const lock_key_feat = getFeatureById(selectedDetail, 'lock_key');
  const audit_log_feat = getFeatureById(selectedDetail, 'audit_log');
  const pdf_ann_feat = getFeatureById(selectedDetail, 'pdf_ann');
  const pdf_edt_feat = getFeatureById(selectedDetail, 'pdf_edt');
  const pdf_sig_feat = getFeatureById(selectedDetail, 'pdf_sig');
  const share_wit_feat = getFeatureById(selectedDetail, 'share_wit');
  const hw_tkn_feat = getFeatureById(selectedDetail, 'hw_tkn');
  const nas_syn_feat = getFeatureById(selectedDetail, 'nas_syn');
  const vip_sup_feat = getFeatureById(selectedDetail, 'vip_sup');

  const plan = {
    plan_id,
    recursion: 'yearly',
    price: 0,
    other_price: 0,
    custom: false,
    locale,
    role_mnt: isValueTrue(role_mnt_feat.plan_1_value),
    access_dny: isValueTrue(access_dny_feat.plan_1_value),
    prom_adm: isValueTrue(prom_adm_feat.plan_1_value),
    block_usr: isValueTrue(block_usr_feat.plan_1_value),
    power_usr: isValueTrue(power_usr_feat.plan_1_value),
    guest_usr: isValueTrue(guest_usr_feat.plan_1_value),
    allow_dev: isValueTrue(allow_dev_feat.plan_1_value),
    paus_syn: isValueTrue(paus_syn_feat.plan_1_value),
    wipe_rmt: isValueTrue(wipe_rmt_feat.plan_1_value),
    role_ctrl: isValueTrue(role_ctrl_feat.plan_1_value),
    perm_itm: isValueTrue(perm_itm_feat.plan_1_value),
    keep_rmt: isValueTrue(keep_rmt_feat.plan_1_value),
    lock_key: isValueTrue(lock_key_feat.plan_1_value),
    audit_log: isValueTrue(audit_log_feat.plan_1_value),
    pdf_ann: isValueTrue(pdf_ann_feat.plan_1_value),
    pdf_edt: isValueTrue(pdf_edt_feat.plan_1_value),
    pdf_sig: isValueTrue(pdf_sig_feat.plan_1_value),
    share_wit: isValueTrue(share_wit_feat.plan_1_value),
    hw_tkn: isValueTrue(hw_tkn_feat.plan_1_value),
    nas_syn: isValueTrue(nas_syn_feat.plan_1_value),
    vip_sup: isValueTrue(vip_sup_feat.plan_1_value),
  };
  return {
    email,
    plan,
    source: 'sms',
    currency: currency.code.toUpperCase(),
  };
};

export const checkStorageEvent = (e: StorageEvent, locale: string) => {
  const currentURL = window.location.href?.split('?')?.[0];
  const shouldNotRedirect = ['login', 'signup', 'email'];
  const isAnyRouteExist = shouldNotRedirect.some((item) =>
    currentURL.includes(item),
  );
  if (isAnyRouteExist) return null;
  if (e.key === 'email') {
    if (e.newValue === null) {
      window.location.href = `/${locale}/sms/login`;
    } else if (!!e.oldValue?.length && e.oldValue !== e.newValue) {
      window.location.href = `/${locale}/sms/login`;
    }
  } else if (e.key === 'access' || e.key === 'refresh') {
    if (e.newValue === null) {
      window.location.href = `/${locale}/sms/login`;
    }
  }
};

export const replaceColorInTheme = (
  theme: 'light' | 'dark',
  htmlString: string,
) => {
  const regex = /#46B01C/gi;
  const regex2 = /#429B1E/gi;
  const regex3 = /rgb\(70,\s*176,\s*28\)/gi;
  const regex4 = /rgb\(66,\s*155,\s*30\)/gi;

  let updatedHTMLString = htmlString;
  if (theme === 'dark') {
    updatedHTMLString = updatedHTMLString?.replace?.(regex4, '#46B01C') || '';
    updatedHTMLString = updatedHTMLString?.replace?.(regex2, '#46B01C') || '';
  } else {
    updatedHTMLString = updatedHTMLString?.replace?.(regex, '#429B1E') || '';
    updatedHTMLString = updatedHTMLString?.replace?.(regex3, '#429B1E') || '';
  }
  return updatedHTMLString;
};

export const convertPlanPayload = (_plan: any) => {
  try {
    let plan = _plan;
    Object.keys(_plan || {}).forEach((key) => {
      if (typeof _plan[key] === 'string') {
        if (_plan[key] === 'false') {
          plan[key] = false;
        } else if (isValueTrue(_plan[key])) {
          if (key === 'pdf_edt') {
            plan[key] = _plan[key];
          } else {
            plan[key] = true;
          }
        }
      }
    });
    return plan;
  } catch (err) {
    return {};
  }
};

export const toBool = (val: any) => {
  if (val === 'coming_soon') return false;
  if (val === 'contact_sales') return false;
  return val === true || val === 'true';
};

export const getPlanPayloadDetails = (
  features: any,
  idx: number,
  recursion: string,
) => {
  try {
    const valueId = `plan_${idx}_value`;

    const role_mnt_feat = getFeatureById(features, 'role_mnt')[valueId];
    const access_dny_feat = getFeatureById(features, 'access_dny')[valueId];
    const prom_adm_feat = getFeatureById(features, 'prom_adm')[valueId];
    const block_usr_feat = getFeatureById(features, 'block_usr')[valueId];
    const power_usr_feat = getFeatureById(features, 'power_usr')[valueId];
    const guest_usr_feat = getFeatureById(features, 'guest_usr')[valueId];
    const allow_dev_feat = getFeatureById(features, 'allow_dev')[valueId];
    const paus_syn_feat = getFeatureById(features, 'paus_syn')[valueId];
    const wipe_rmt_feat = getFeatureById(features, 'wipe_rmt')[valueId];
    const role_ctrl_feat = getFeatureById(features, 'role_ctrl')[valueId];
    const perm_itm_feat = getFeatureById(features, 'perm_itm')[valueId];
    const keep_rmt_feat = getFeatureById(features, 'keep_rmt')[valueId];
    const lock_key_feat = getFeatureById(features, 'lock_key')[valueId];
    const audit_log_feat = getFeatureById(features, 'audit_log')[valueId];
    const pdf_ann_feat = getFeatureById(features, 'pdf_ann')[valueId];
    const pdf_edt_feat = getFeatureById(features, 'pdf_edt')[valueId];
    const share_wit_feat = getFeatureById(features, 'share_wit')[valueId];
    const hw_tkn_feat = getFeatureById(features, 'hw_tkn')[valueId];
    const nas_syn_feat = getFeatureById(features, 'nas_syn')[valueId];
    const vip_sup_feat = getFeatureById(features, 'vip_sup')[valueId];
    const inv_usr_feat = getFeatureById(features, 'inv_usr')[valueId];
    const del_usr_feat = getFeatureById(features, 'del_usr')[valueId];
    const activ_dev_feat = getFeatureById(features, 'activ_dev')[valueId];
    const pdf_sig_feat = getFeatureById(features, 'pdf_sig')[valueId];
    return {
      recursion,
      role_mnt: role_mnt_feat === '.' ? false : toBool(role_mnt_feat),
      access_dny: access_dny_feat === '.' ? false : toBool(access_dny_feat),
      prom_adm: prom_adm_feat === '.' ? false : toBool(prom_adm_feat),
      block_usr: block_usr_feat === '.' ? false : toBool(block_usr_feat),
      power_usr: power_usr_feat === '.' ? false : toBool(power_usr_feat),
      guest_usr: guest_usr_feat === '.' ? false : toBool(guest_usr_feat),
      allow_dev: allow_dev_feat === '.' ? false : toBool(allow_dev_feat),
      paus_syn: paus_syn_feat === '.' ? false : toBool(paus_syn_feat),
      wipe_rmt: wipe_rmt_feat === '.' ? false : toBool(wipe_rmt_feat),
      role_ctrl: role_ctrl_feat === '.' ? false : toBool(role_ctrl_feat),
      perm_itm: perm_itm_feat === '.' ? false : toBool(perm_itm_feat),
      keep_rmt: keep_rmt_feat === '.' ? false : toBool(keep_rmt_feat),
      lock_key: lock_key_feat === '.' ? false : toBool(lock_key_feat),
      audit_log: audit_log_feat === '.' ? false : toBool(audit_log_feat),
      pdf_ann: pdf_ann_feat === '.' ? false : toBool(pdf_ann_feat),
      share_wit: share_wit_feat === '.' ? false : toBool(share_wit_feat),
      hw_tkn: hw_tkn_feat === '.' ? false : toBool(hw_tkn_feat),
      nas_syn: nas_syn_feat === '.' ? false : toBool(nas_syn_feat),
      vip_sup: vip_sup_feat === '.' ? false : toBool(vip_sup_feat),
      inv_usr: inv_usr_feat === '.' ? false : toBool(inv_usr_feat),
      del_usr: del_usr_feat === '.' ? false : toBool(del_usr_feat),
      activ_dev: activ_dev_feat === '.' ? false : toBool(activ_dev_feat),
      pdf_sig: pdf_sig_feat === '.' ? false : toBool(pdf_sig_feat),
      ...(isValueTrue(pdf_edt_feat) ? { pdf_edt: pdf_edt_feat } : {}),
    };
  } catch (err) {
    /* empty */
  }
};

export const convertOptionsToFeaturesSafe = (params: any, plansData: any) => {
  try {
    const {
      plan_id,
      recursion,
      role_mnt,
      access_dny,
      prom_adm,
      block_usr,
      power_usr,
      guest_usr,
      allow_dev,
      paus_syn,
      wipe_rmt,
      role_ctrl,
      perm_itm,
      keep_rmt,
      lock_key,
      audit_log,
      pdf_ann,
      pdf_edt,
      share_wit,
      hw_tkn,
      nas_syn,
      vip_sup,
      inv_usr,
      del_usr,
      activ_dev,
      pdf_sig,
    } = params;
    const { plan_features: tempFeatures, pricing_plans } = plansData;
    const fullPlan = pricing_plans.filter((p: any) => p.plan_id === plan_id)[0];
    const idx = pricing_plans.indexOf(fullPlan) + 1 || 2;
    const valueId = `plan_${idx}_value`;
    const role_mnt_feat = getIndexesOfFeature(tempFeatures, 'role_mnt');
    if (role_mnt) {
      if (role_mnt_feat[2] > -1) {
        tempFeatures[role_mnt_feat[0]].details[role_mnt_feat[1]].details[
          role_mnt_feat[2]
        ][valueId] = role_mnt;
      } else {
        tempFeatures[role_mnt_feat[0]].details[role_mnt_feat[1]][valueId] =
          role_mnt;
      }
    }
    const access_dny_feat = getIndexesOfFeature(tempFeatures, 'access_dny');
    if (access_dny) {
      if (access_dny_feat[2] > -1) {
        tempFeatures[access_dny_feat[0]].details[access_dny_feat[1]].details[
          access_dny_feat[2]
        ][valueId] = access_dny;
      } else {
        tempFeatures[access_dny_feat[0]].details[access_dny_feat[1]][valueId] =
          access_dny;
      }
    }

    const prom_adm_feat = getIndexesOfFeature(tempFeatures, 'prom_adm');
    if (prom_adm) {
      if (prom_adm_feat[2] > -1) {
        tempFeatures[prom_adm_feat[0]].details[prom_adm_feat[1]].details[
          prom_adm_feat[2]
        ][valueId] = prom_adm;
      } else {
        tempFeatures[prom_adm_feat[0]].details[prom_adm_feat[1]][valueId] =
          prom_adm;
      }
    }

    const block_usr_feat = getIndexesOfFeature(tempFeatures, 'block_usr');
    if (block_usr) {
      if (block_usr_feat[2] > -1) {
        tempFeatures[block_usr_feat[0]].details[block_usr_feat[1]].details[
          block_usr_feat[2]
        ][valueId] = block_usr;
      } else {
        tempFeatures[block_usr_feat[0]].details[block_usr_feat[1]][valueId] =
          block_usr;
      }
    }

    const power_usr_feat = getIndexesOfFeature(tempFeatures, 'power_usr');

    if (power_usr) {
      if (power_usr_feat[2] > -1) {
        tempFeatures[power_usr_feat[0]].details[power_usr_feat[1]].details[
          power_usr_feat[2]
        ][valueId] = power_usr;
      } else {
        tempFeatures[power_usr_feat[0]].details[power_usr_feat[1]][valueId] =
          power_usr;
      }
    }

    const guest_usr_feat = getIndexesOfFeature(tempFeatures, 'guest_usr');
    if (guest_usr) {
      if (guest_usr_feat[2] > -1) {
        tempFeatures[guest_usr_feat[0]].details[guest_usr_feat[1]].details[
          guest_usr_feat[2]
        ][valueId] = guest_usr;
      } else {
        tempFeatures[guest_usr_feat[0]].details[guest_usr_feat[1]][valueId] =
          guest_usr;
      }
    }

    const allow_dev_feat = getIndexesOfFeature(tempFeatures, 'allow_dev');
    if (allow_dev) {
      if (allow_dev_feat[2] > -1) {
        tempFeatures[allow_dev_feat[0]].details[allow_dev_feat[1]].details[
          allow_dev_feat[2]
        ][valueId] = allow_dev;
      } else {
        tempFeatures[allow_dev_feat[0]].details[allow_dev_feat[1]][valueId] =
          allow_dev;
      }
    }

    const paus_syn_feat = getIndexesOfFeature(tempFeatures, 'paus_syn');
    if (paus_syn) {
      if (paus_syn_feat[2] > -1) {
        tempFeatures[paus_syn_feat[0]].details[paus_syn_feat[1]].details[
          paus_syn_feat[2]
        ][valueId] = paus_syn;
      } else {
        tempFeatures[paus_syn_feat[0]].details[paus_syn_feat[1]][valueId] =
          paus_syn;
      }
    }

    const wipe_rmt_feat = getIndexesOfFeature(tempFeatures, 'wipe_rmt');
    if (wipe_rmt) {
      if (wipe_rmt_feat[2] > -1) {
        tempFeatures[wipe_rmt_feat[0]].details[wipe_rmt_feat[1]].details[
          wipe_rmt_feat[2]
        ][valueId] = wipe_rmt;
      } else {
        tempFeatures[wipe_rmt_feat[0]].details[wipe_rmt_feat[1]][valueId] =
          wipe_rmt;
      }
    }

    const role_ctrl_feat = getIndexesOfFeature(tempFeatures, 'role_ctrl');
    if (role_ctrl) {
      if (role_ctrl_feat[2] > -1) {
        tempFeatures[role_ctrl_feat[0]].details[role_ctrl_feat[1]].details[
          role_ctrl_feat[2]
        ][valueId] = role_ctrl;
      } else {
        tempFeatures[role_ctrl_feat[0]].details[role_ctrl_feat[1]][valueId] =
          role_ctrl;
      }
    }

    const perm_itm_feat = getIndexesOfFeature(tempFeatures, 'perm_itm');
    if (perm_itm) {
      if (perm_itm_feat[2] > -1) {
        tempFeatures[perm_itm_feat[0]].details[perm_itm_feat[1]].details[
          perm_itm_feat[2]
        ][valueId] = perm_itm;
      } else {
        tempFeatures[perm_itm_feat[0]].details[perm_itm_feat[1]][valueId] =
          perm_itm;
      }
    }

    const keep_rmt_feat = getIndexesOfFeature(tempFeatures, 'keep_rmt');
    if (keep_rmt) {
      if (keep_rmt_feat[2] > -1) {
        tempFeatures[keep_rmt_feat[0]].details[keep_rmt_feat[1]].details[
          keep_rmt_feat[2]
        ][valueId] = keep_rmt;
      } else {
        tempFeatures[keep_rmt_feat[0]].details[keep_rmt_feat[1]][valueId] =
          keep_rmt;
      }
    }

    const lock_key_feat = getIndexesOfFeature(tempFeatures, 'lock_key');
    if (lock_key) {
      if (lock_key_feat[2] > -1) {
        tempFeatures[lock_key_feat[0]].details[lock_key_feat[1]].details[
          lock_key_feat[2]
        ][valueId] = lock_key;
      } else {
        tempFeatures[lock_key_feat[0]].details[lock_key_feat[1]][valueId] =
          lock_key;
      }
    }

    const audit_log_feat = getIndexesOfFeature(tempFeatures, 'audit_log');
    if (audit_log) {
      if (audit_log_feat[2] > -1) {
        tempFeatures[audit_log_feat[0]].details[audit_log_feat[1]].details[
          audit_log_feat[2]
        ][valueId] = audit_log;
      } else {
        tempFeatures[audit_log_feat[0]].details[audit_log_feat[1]][valueId] =
          audit_log;
      }
    }

    const pdf_ann_feat = getIndexesOfFeature(tempFeatures, 'pdf_ann');
    if (pdf_ann) {
      if (pdf_ann_feat[2] > -1) {
        tempFeatures[pdf_ann_feat[0]].details[pdf_ann_feat[1]].details[
          pdf_ann_feat[2]
        ][valueId] = pdf_ann;
      } else {
        tempFeatures[pdf_ann_feat[0]].details[pdf_ann_feat[1]][valueId] =
          pdf_ann;
      }
    }

    const pdf_edt_feat = getIndexesOfFeature(tempFeatures, 'pdf_edt');
    if (pdf_edt) {
      if (pdf_edt_feat[2] > -1) {
        tempFeatures[pdf_edt_feat[0]].details[pdf_edt_feat[1]].details[
          pdf_edt_feat[2]
        ][valueId] = pdf_edt;
      } else {
        tempFeatures[pdf_edt_feat[0]].details[pdf_edt_feat[1]][valueId] =
          pdf_edt;
      }
    }

    const share_wit_feat = getIndexesOfFeature(tempFeatures, 'share_wit');
    if (share_wit) {
      if (share_wit_feat[2] > -1) {
        tempFeatures[share_wit_feat[0]].details[share_wit_feat[1]].details[
          share_wit_feat[2]
        ][valueId] = share_wit;
      } else {
        tempFeatures[share_wit_feat[0]].details[share_wit_feat[1]][valueId] =
          share_wit;
      }
    }

    const hw_tkn_feat = getIndexesOfFeature(tempFeatures, 'hw_tkn');
    if (hw_tkn) {
      if (hw_tkn_feat[2] > -1) {
        tempFeatures[hw_tkn_feat[0]].details[hw_tkn_feat[1]].details[
          hw_tkn_feat[2]
        ][valueId] = hw_tkn;
      } else {
        tempFeatures[hw_tkn_feat[0]].details[hw_tkn_feat[1]][valueId] = hw_tkn;
      }
    }

    const nas_syn_feat = getIndexesOfFeature(tempFeatures, 'nas_syn');
    if (nas_syn) {
      if (nas_syn_feat[2] > -1) {
        tempFeatures[nas_syn_feat[0]].details[nas_syn_feat[1]].details[
          nas_syn_feat[2]
        ][valueId] = nas_syn;
      } else {
        tempFeatures[nas_syn_feat[0]].details[nas_syn_feat[1]][valueId] =
          nas_syn;
      }
    }

    const vip_sup_feat = getIndexesOfFeature(tempFeatures, 'vip_sup');
    if (vip_sup) {
      if (vip_sup_feat[2] > -1) {
        tempFeatures[vip_sup_feat[0]].details[vip_sup_feat[1]].details[
          vip_sup_feat[2]
        ][valueId] = vip_sup;
      } else {
        tempFeatures[vip_sup_feat[0]].details[vip_sup_feat[1]][valueId] =
          vip_sup;
      }
    }

    const inv_usr_feat = getIndexesOfFeature(tempFeatures, 'inv_usr');
    if (inv_usr) {
      if (inv_usr_feat[2] > -1) {
        tempFeatures[inv_usr_feat[0]].details[inv_usr_feat[1]].details[
          inv_usr_feat[2]
        ][valueId] = inv_usr;
      } else {
        tempFeatures[inv_usr_feat[0]].details[inv_usr_feat[1]][valueId] =
          inv_usr;
      }
    }

    const del_usr_feat = getIndexesOfFeature(tempFeatures, 'del_usr');
    if (del_usr) {
      if (del_usr_feat[2] > -1) {
        tempFeatures[del_usr_feat[0]].details[del_usr_feat[1]].details[
          del_usr_feat[2]
        ][valueId] = del_usr;
      } else {
        tempFeatures[del_usr_feat[0]].details[del_usr_feat[1]][valueId] =
          del_usr;
      }
    }

    const activ_dev_feat = getIndexesOfFeature(tempFeatures, 'activ_dev');
    if (activ_dev) {
      if (activ_dev_feat[2] > -1) {
        tempFeatures[activ_dev_feat[0]].details[activ_dev_feat[1]].details[
          activ_dev_feat[2]
        ][valueId] = activ_dev;
      } else {
        tempFeatures[activ_dev_feat[0]].details[activ_dev_feat[1]][valueId] =
          activ_dev;
      }
    }

    const pdf_sig_feat = getIndexesOfFeature(tempFeatures, 'pdf_sig');
    if (pdf_sig) {
      if (pdf_sig_feat[2] > -1) {
        tempFeatures[pdf_sig_feat[0]].details[pdf_sig_feat[1]].details[
          pdf_sig_feat[2]
        ][valueId] = pdf_sig;
      } else {
        tempFeatures[pdf_sig_feat[0]].details[pdf_sig_feat[1]][valueId] =
          pdf_sig;
      }
    }
    const toPayload = getPlanPayloadDetails(tempFeatures, idx, recursion);
    return { tempFeatures, toPayload, idx, params };
  } catch (err) {
    return {};
  }
};

export const checkIfPlanIsCustom = (features: any, index: any) => {
  const valueKey = `plan_${index}_value`;
  const editableKey = `is_editable_for_plan_${index}`;
  let isCustom = false;
  if (!features?.length) {
    return isCustom;
  }

  const allFeatures = [
    getFeatureById(features, 'role_mnt'),
    getFeatureById(features, 'access_dny'),
    getFeatureById(features, 'prom_adm'),
    getFeatureById(features, 'block_usr'),
    getFeatureById(features, 'power_usr'),
    getFeatureById(features, 'guest_usr'),
    getFeatureById(features, 'allow_dev'),
    getFeatureById(features, 'paus_syn'),
    getFeatureById(features, 'wipe_rmt'),
    getFeatureById(features, 'role_ctrl'),
    getFeatureById(features, 'perm_itm'),
    getFeatureById(features, 'keep_rmt'),
    getFeatureById(features, 'lock_key'),
    getFeatureById(features, 'audit_log'),
    getFeatureById(features, 'pdf_ann'),
    getFeatureById(features, 'pdf_edt'),
    getFeatureById(features, 'share_wit'),
    getFeatureById(features, 'hw_tkn'),
    getFeatureById(features, 'nas_syn'),
    getFeatureById(features, 'vip_sup'),
  ];
  allFeatures.map((feature) => {
    const isEditable = feature[editableKey];
    const value = feature[valueKey];
    const isTrue = isValueTrue(value);

    if (isEditable && isTrue) {
      isCustom = true;
    }
  });
  return isCustom;
};

export const addLangToHtml = () => {
  try {
    const availableLanguages = [
      {
        language_code: 'fr',
      },
      {
        language_code: 'de',
      },
      {
        language_code: 'es',
      },
      {
        language_code: 'it',
      },
      {
        language_code: 'ru',
      },
      {
        language_code: 'pt',
      },
      {
        language_code: 'en',
      },
    ];

    const currentURL = window.location.pathname;
    const currentURLWithoutSearchParamsAndWithoutHash = currentURL
      .split('?')[0]
      .split('#')[0];
    const lang = availableLanguages.find((item) =>
      currentURLWithoutSearchParamsAndWithoutHash.includes(item.language_code),
    );
    if (lang) {
      document.documentElement.lang = lang.language_code;
    } else {
      document.documentElement.lang = 'en';
    }
  } catch (err) {
    // DO NOTHING
  }
};

const flatten: any = (arr: any[]) =>
  arr.reduce(
    (acc, val) =>
      Array.isArray(val) ? acc.concat(flatten(val)) : acc.concat(val),
    [],
  );

export function encodeString(originalString: string, features: IPlanFeature[]) {
  let flattenFeatures = flatten(
    features.map((feature) => feature?.details || []),
  ) as IPlanFeatureDetail[];
  flattenFeatures = flatten(
    flattenFeatures.map((feature) =>
      feature?.details?.length ? feature?.details : feature,
    ),
  );

  return originalString
    .split(',')
    .map((item) => {
      const matchingItem = flattenFeatures.find(
        (featureItem) => featureItem.detail_id === item,
      );
      return matchingItem ? matchingItem.two_char_id : '';
    })
    .join(',');
}
