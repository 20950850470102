import qs from 'qs';
import axios from 'axios';
import { Locale } from 'types/common';
import { FormattedLocales } from '@config/locales';
import { error, log, getCache, setCache, setupCache } from '@utils/apis';
import {
  FeaturedSectionsDatum,
  FeaturesEntity,
  NavbarData,
} from 'types/strapi/language';
import { IMedia } from 'types/strapi';
import _ from 'lodash';
import { HeroData } from 'types/strapi/language/pricing';
import { BodyData } from 'types/strapi/language/why-quodarca';
import { BodyData as AboutUsBody } from 'types/strapi/language/aboutUs';
import { BodyData as iosAndAndroidBody } from 'types/strapi/language/iosAndAndroid';
import {
  FeaturesData,
  BodyData as MacAndWindowsBody,
} from 'types/strapi/language/macAndWindows';
import { MEDIA_URL, STRAPI_URL, headers as StrapiHeaders } from '@config/index';

export const getFileTypeFromfileName = (fileName = '') => {
  const fileExtension = fileName.split('.').pop() || 'pdf';
  const fileTypes = {
    svg: 'image/svg+xml',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    webp: 'image/webp',
    gif: 'image/gif',
    bmp: 'image/bmp',
    tiff: 'image/tiff',
    mp4: 'video/mp4',
    webm: 'video/webm',
    ogg: 'video/ogg',
  };
  return (
    fileTypes[fileExtension as keyof typeof fileTypes] || 'application/pdf'
  );
};

const cache = setupCache();
export const fetchFile = async (tempURL: string) => {
  let url = tempURL as string;
  try {
    url = decodeURIComponent(tempURL);
  } catch (err) {
    url = tempURL || '';
  }

  if (!url) {
    return '';
  }

  const cacheKey = `get-file-${url}`;
  const cachedData = getCache(cache, cacheKey);
  if (cachedData) {
    try {
      return cachedData;
    } catch (err) {
      // empty
    }
  }
  try {
    const response = await axios.get(url, {
      responseType: 'arraybuffer',
    });
    const base64 = Buffer.from(response.data, 'binary').toString('base64');
    const formattedBase64 = `data:${getFileTypeFromfileName(
      url,
    )};base64,${base64}`;
    setCache(cache, cacheKey, formattedBase64);
    return formattedBase64;
  } catch (err) {
    return '';
  }
};

export const login = async (body: any) => {
  const res = await axios.post(`/api/auth/local`, body);
  return res;
};

export const validateUser = async (token = '') => {
  let isAuthenticated = false;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  await axios
    .get(`/api/auth/me`, {
      headers,
    })
    .then((res) => {
      const data = res.data;
      if (!data.blocked) {
        isAuthenticated = true;
      } else {
        isAuthenticated = false;
      }
    })
    .catch(() => {
      isAuthenticated = false;
    });
  return isAuthenticated;
};

export const getFile = async (populate = '', token = '', idx = 0) => {
  if (populate.length && populate.includes('.')) {
    const [f, s, t] = populate.split('.');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const res = await axios.get(
        `/api/auth/get-file?f=${f}&s=${s}&populate=${populate}`,
        {
          headers,
        },
      );
      const sorted = res.data.data.attributes[f].sort(
        (a: { id: number }, b: { id: number }) => b.id - a.id,
      );
      return sorted?.[idx]?.[s]?.[t]?.data?.attributes;
    } catch (err) {
      return err;
    }
  }
  return null;
};

export const getFileURLToDownload = (image: {
  data: { attributes: { url: string } };
}) => {
  let url = image?.data?.attributes?.url || '/logo-light.svg';
  if (url === '/logo-light.svg') {
    return url;
  }
  if (url.startsWith('/')) {
    url = MEDIA_URL + url;
  }
  if (!url.startsWith('https://')) {
    url = `https://${url}`;
  }
  return url;
};

export const fetchQuodArcaReleases = async (locale: string) => {
  const resp = await axios
    .get(`/api/auth/get-releases?locale=${locale}`, {
      headers: StrapiHeaders,
    })
    .then((res) => res.data.data.attributes)
    .catch((err) => err);
  return resp;
};

export const sleep = (milliseconds: number) =>
  new Promise((resolve) => setTimeout(resolve, milliseconds));

export const getWithRetry: any = async (
  url: string,
  retries: number,
  delay: number = 1000,
) => {
  try {
    log('getWithRetry', { url, retries, delay });
    const response = await axios.get(url, {
      headers: StrapiHeaders,
    });
    log('getWithRetry response', {
      url,
      retries,
      delay,
      response: response?.status,
    });
    return response;
  } catch (err: any) {
    error(`Failed to fetch ${url}, retries left: ${retries}, err: ${err}`, {
      retries,
      delay,
      url,
      err,
    });
    if (err?.response && [404, 403].includes(err?.response?.status)) {
      throw err;
    }
    if (retries === 1 || !retries) {
      throw err;
    }

    const jitter = Math.random() * 100;
    log('Retrying...', {
      retries,
      delay,
      url,
      jitter,
    });
    await sleep(delay + jitter);
    log('After sleep', {
      retries,
      delay,
      url,
      delayJitter: delay + jitter,
    });
    return getWithRetry(url, retries - 1, delay * 2);
  }
};

export const getAllAvailableLocales = async () => {
  const localesQuery = qs.stringify(
    {
      populate: ['language'],
      locale: 'en',
    },
    {
      encodeValuesOnly: true,
    },
  );
  let locales: { language_code: Locale }[] = [];
  await axios
    .get(
      `${STRAPI_URL}/available-language?${localesQuery}&publicationState=preview`,
    )
    .then((res) => {
      locales = res.data.data.attributes.language;
    })
    .catch((err) => err);
  return locales || FormattedLocales;
};

export const isLocaleValid = async (locale: string) => {
  const locales = await getAllAvailableLocales();
  const isValid = locales.some((l) => l.language_code === locale);
  return isValid;
};

export const fetchURLAndCovertResponseToBase64 = async (url: string) => {
  const response = await fetchFile(url);
  return response;
};

export const getMediaTypeForDownload = (media?: IMedia) => {
  if (media?.data?.attributes?.mime) {
    return (_.get(media, 'data.attributes.mime')! as string)?.includes('video')
      ? 'video'
      : 'image';
  }
  return 'image';
};

export const getImageUrlForDownload = (image?: IMedia, highQuality = false) => {
  let url =
    _.get(image, 'data.attributes.url') || _.get(image, 'attributes.url') || '';
  if (!url) {
    return '';
  }
  if (/^[a-zA-Z]/.test(url) && !/^https?:\/\//.test(url)) {
    url = `https://${url}`;
  } else if (url.startsWith('/')) {
    url = MEDIA_URL + url;
  }
  if (
    image &&
    getMediaTypeForDownload(image) === 'video' &&
    !url.includes('https://') &&
    !url.includes('http://')
  ) {
    url = `https://${url}`;
  }
  if (highQuality) {
    return `${url} 2x`;
  }
  return url;
};

export const getFeaturesWithBase64Icons = async (
  features: FeaturesEntity[],
) => {
  if (!features?.length) return [];

  const featuresWithBase64Icons = await Promise.all(
    features.map(async (feature) => {
      try {
        const defaultLightIcon = getImageUrlForDownload(
          feature?.default_light_icon as IMedia,
        );
        const defaultDarkIcon = getImageUrlForDownload(
          feature?.default_dark_icon as IMedia,
        );
        const selectedLightIcon = getImageUrlForDownload(
          feature?.selected_light_icon as IMedia,
        );
        const selectedDarkIcon = getImageUrlForDownload(
          feature?.selected_dark_icon as IMedia,
        );

        const newIcons: {
          default_light_icon?: string;
          default_dark_icon?: string;
          selected_light_icon?: string;
          selected_dark_icon?: string;
        } = {};

        if (defaultLightIcon) {
          const defaultLightIconBase64 =
            await fetchURLAndCovertResponseToBase64(defaultLightIcon);
          newIcons.default_light_icon = defaultLightIconBase64;
        }
        if (defaultDarkIcon) {
          const defaultDarkIconBase64 =
            await fetchURLAndCovertResponseToBase64(defaultDarkIcon);
          newIcons.default_dark_icon = defaultDarkIconBase64;
        }
        if (selectedLightIcon) {
          const selectedLightIconBase64 =
            await fetchURLAndCovertResponseToBase64(selectedLightIcon);
          newIcons.selected_light_icon = selectedLightIconBase64;
        }
        if (selectedDarkIcon) {
          const selectedDarkIconBase64 =
            await fetchURLAndCovertResponseToBase64(selectedDarkIcon);
          newIcons.selected_dark_icon = selectedDarkIconBase64;
        }
        return {
          ...feature,
          ...newIcons,
        };
      } catch (err) {
        return {
          ...feature,
        };
      }
    }),
  );

  return featuresWithBase64Icons;
};

export const getFeaturesWithBase64Images = async (
  features: FeaturesEntity[],
) => {
  if (!features?.length) return [];

  const featuresWithBase64Images = await Promise.all(
    features.map(async (feature) => {
      try {
        const dark_mobile_image = getImageUrlForDownload(
          feature?.dark_mobile_image,
        );
        const mobile_image = getImageUrlForDownload(feature?.mobile_image);
        const dark_tablet_image = getImageUrlForDownload(
          feature?.dark_tablet_image,
        );
        const tablet_image = getImageUrlForDownload(feature?.tablet_image);
        const dark_media = getImageUrlForDownload(feature?.dark_media);
        const light_media = getImageUrlForDownload(feature?.light_media);

        const newImages: {
          dark_mobile_image?: string;
          mobile_image?: string;
          dark_tablet_image?: string;
          tablet_image?: string;
          dark_media?: string;
          light_media?: string;
        } = {};

        if (dark_mobile_image) {
          const darkMobileImage =
            await fetchURLAndCovertResponseToBase64(dark_mobile_image);
          newImages.dark_mobile_image = darkMobileImage;
        }
        if (mobile_image) {
          const mobileImage =
            await fetchURLAndCovertResponseToBase64(mobile_image);
          newImages.mobile_image = mobileImage;
        }
        if (dark_tablet_image) {
          const darkTabletImage =
            await fetchURLAndCovertResponseToBase64(dark_tablet_image);
          newImages.dark_tablet_image = darkTabletImage;
        }
        if (tablet_image) {
          const tabletImage =
            await fetchURLAndCovertResponseToBase64(tablet_image);
          newImages.tablet_image = tabletImage;
        }
        if (dark_media) {
          const darkMedia = await fetchURLAndCovertResponseToBase64(dark_media);
          newImages.dark_media = darkMedia;
        }
        if (light_media) {
          const lightMedia =
            await fetchURLAndCovertResponseToBase64(light_media);
          newImages.light_media = lightMedia;
        }
        return {
          ...feature,
          ...newImages,
        };
      } catch (err) {
        return {
          ...feature,
        };
      }
    }),
  );

  return featuresWithBase64Images;
};

export const convertGetStartedMediaToBase64 = async (
  getStartedSectionData: any,
) => {
  if (!getStartedSectionData?.steps?.length) {
    return getStartedSectionData?.steps || [];
  }
  const resp = await Promise.all(
    getStartedSectionData?.steps?.map?.(async (mediaObject: any) => {
      const media = getImageUrlForDownload(mediaObject?.media);
      const darkMedia = getImageUrlForDownload(mediaObject?.dark_media);
      const base64Content: any = {};
      if (media?.length) {
        const mediaURL = getImageUrlForDownload(mediaObject?.media);
        const base64Downloaded =
          await fetchURLAndCovertResponseToBase64(mediaURL);
        base64Content.media = base64Downloaded;
      }
      if (darkMedia?.length) {
        const darkMediaURL = getImageUrlForDownload(mediaObject?.dark_media);
        const darkBase64Downloaded =
          await fetchURLAndCovertResponseToBase64(darkMediaURL);
        base64Content.dark_media = darkBase64Downloaded;
      }
      return {
        ...mediaObject,
        base64Content,
      };
    }),
  );
  return resp;
};

export const convertFeaturedSectionMediaToBase64 = async (
  featuredSectionsData: FeaturedSectionsDatum[],
) => {
  if (!featuredSectionsData?.length) {
    return featuredSectionsData;
  }

  const resp = await Promise.all(
    featuredSectionsData.map(async (section) => {
      const media_layout = section?.media_layout;
      const lightSelectMedia = {
        useMobileAndTablet: media_layout === 'Tablet and Mobile',
        useDesktopAndMobile: media_layout === 'Laptop and Mobile',
        useDesktopOnly: media_layout === 'Laptop',
        useTabletOnly: media_layout === 'Tablet',
      };
      const darkSelectMedia = {
        useMobileAndTablet: media_layout === 'Tablet and Mobile',
        useDesktopAndMobile: media_layout === 'Laptop and Mobile',
        useDesktopOnly: media_layout === 'Laptop',
        useTabletOnly: media_layout === 'Tablet',
      };
      let newObject: FeaturedSectionsDatum = {};
      if (lightSelectMedia.useMobileAndTablet) {
        const tabletImage = getImageUrlForDownload(section?.tablet_image);
        const mobileImage = getImageUrlForDownload(section?.mobile_image);
        const tabletImageBase64 =
          await fetchURLAndCovertResponseToBase64(tabletImage);
        const mobileImageBase64 =
          await fetchURLAndCovertResponseToBase64(mobileImage);
        newObject = {
          ...section,
          tablet_image: {
            data: {
              attributes: {
                ...section.tablet_image!.data.attributes,
                url: tabletImageBase64,
              },
            },
          },
          mobile_image: {
            data: {
              attributes: {
                ...section.mobile_image!.data.attributes,
                url: mobileImageBase64,
              },
            },
          },
        };
      } else if (lightSelectMedia.useDesktopAndMobile) {
        const desktopImage = getImageUrlForDownload(section?.media);
        const mobileImage = getImageUrlForDownload(section?.mobile_image);
        const desktopImageBase64 =
          await fetchURLAndCovertResponseToBase64(desktopImage);
        const mobileImageBase64 =
          await fetchURLAndCovertResponseToBase64(mobileImage);
        newObject = {
          ...section,
          media: {
            data: {
              attributes: {
                ...section.media!.data.attributes,
                url: desktopImageBase64,
              },
            },
          },
          mobile_image: {
            data: {
              attributes: {
                ...section.mobile_image!.data.attributes,
                url: mobileImageBase64,
              },
            },
          },
        };
      } else if (lightSelectMedia.useTabletOnly) {
        const tabletImage = getImageUrlForDownload(section?.tablet_image);
        const tabletImageBase64 =
          await fetchURLAndCovertResponseToBase64(tabletImage);
        newObject = {
          ...section,
          tablet_image: {
            data: {
              attributes: {
                ...section.tablet_image!.data.attributes,
                url: tabletImageBase64,
              },
            },
          },
        };
      } else {
        const desktopImage = getImageUrlForDownload(section?.media);
        const desktopImageBase64 =
          await fetchURLAndCovertResponseToBase64(desktopImage);
        newObject = {
          ...section,
          media: {
            data: {
              attributes: {
                ...section.media!.data.attributes,
                url: desktopImageBase64,
              },
            },
          },
        };
      }
      if (darkSelectMedia.useMobileAndTablet) {
        const darkTabletImage = section?.dark_tablet_image?.data?.attributes
          ?.url
          ? getImageUrlForDownload(section?.dark_tablet_image)
          : getImageUrlForDownload(section?.tablet_image);
        const darkMobileImage = section?.dark_mobile_image?.data?.attributes
          ? getImageUrlForDownload(section?.dark_mobile_image)
          : getImageUrlForDownload(section?.mobile_image);
        const darkTabletImageBase64 =
          await fetchURLAndCovertResponseToBase64(darkTabletImage);
        const darkMobileImageBase64 =
          await fetchURLAndCovertResponseToBase64(darkMobileImage);
        newObject = {
          ...newObject,
          dark_tablet_image: {
            data: {
              attributes: {
                ...section.dark_tablet_image!.data.attributes,
                url: darkTabletImageBase64,
              },
            },
          },
          dark_mobile_image: {
            data: {
              attributes: {
                ...section.dark_mobile_image!.data.attributes,
                url: darkMobileImageBase64,
              },
            },
          },
        };
      } else if (darkSelectMedia.useDesktopAndMobile) {
        const darkDesktopImage = section?.dark_media?.data?.attributes?.url
          ? getImageUrlForDownload(section?.dark_media)
          : getImageUrlForDownload(section?.media);
        const darkMobileImage = section?.dark_mobile_image?.data?.attributes
          ? getImageUrlForDownload(section?.dark_mobile_image)
          : getImageUrlForDownload(section?.mobile_image);
        const darkDesktopImageBase64 =
          await fetchURLAndCovertResponseToBase64(darkDesktopImage);
        const darkMobileImageBase64 =
          await fetchURLAndCovertResponseToBase64(darkMobileImage);
        newObject = {
          ...newObject,
          dark_media: {
            data: {
              attributes: {
                ...section.dark_media!.data.attributes,
                url: darkDesktopImageBase64,
              },
            },
          },
          dark_mobile_image: {
            data: {
              attributes: {
                ...section.dark_mobile_image!.data.attributes,
                url: darkMobileImageBase64,
              },
            },
          },
        };
      } else if (darkSelectMedia.useTabletOnly) {
        const darkTabletImage = section?.dark_tablet_image?.data?.attributes
          ?.url
          ? getImageUrlForDownload(section?.dark_tablet_image)
          : getImageUrlForDownload(section?.tablet_image);
        const darkTabletImageBase64 =
          await fetchURLAndCovertResponseToBase64(darkTabletImage);
        newObject = {
          ...newObject,
          dark_tablet_image: {
            data: {
              attributes: {
                ...section.dark_tablet_image!.data.attributes,
                url: darkTabletImageBase64,
              },
            },
          },
        };
      } else {
        const darkDesktopImage = section?.dark_media?.data?.attributes?.url
          ? getImageUrlForDownload(section?.dark_media)
          : getImageUrlForDownload(section?.media);
        const darkDesktopImageBase64 =
          await fetchURLAndCovertResponseToBase64(darkDesktopImage);
        newObject = {
          ...newObject,
          dark_media: {
            data: {
              attributes: {
                ...section.dark_media!.data.attributes,
                url: darkDesktopImageBase64,
              },
            },
          },
        };
      }
      return newObject;
    }),
  );
  return resp;
};

export const convertPricingHeroImagesToBase64 = async (heroData: HeroData) => {
  const responseObj: HeroData = {
    ...heroData,
  };
  try {
    if (heroData?.retina_image?.data?.attributes?.url) {
      const retinaImage = getImageUrlForDownload(heroData?.retina_image);
      const retinaImageBase64 =
        await fetchURLAndCovertResponseToBase64(retinaImage);
      responseObj.retina_image = {
        data: {
          attributes: {
            ...heroData.retina_image.data.attributes,
            url: retinaImageBase64,
          },
        },
      };
    }
    if (heroData?.small_devices_image?.data?.attributes?.url) {
      const smallDevicesImage = getImageUrlForDownload(
        heroData?.small_devices_image,
      );
      const smallDevicesImageBase64 =
        await fetchURLAndCovertResponseToBase64(smallDevicesImage);
      responseObj.small_devices_image = {
        data: {
          attributes: {
            ...heroData.small_devices_image.data.attributes,
            url: smallDevicesImageBase64,
          },
        },
      };
    }
    if (heroData?.tablet_image?.data?.attributes?.url) {
      const tabletImage = getImageUrlForDownload(heroData?.tablet_image);
      const tabletImageBase64 =
        await fetchURLAndCovertResponseToBase64(tabletImage);
      responseObj.tablet_image = {
        data: {
          attributes: {
            ...heroData.tablet_image.data.attributes,
            url: tabletImageBase64,
          },
        },
      };
    }
    return responseObj;
  } catch (err) {
    return heroData;
  }
};

export const convertNavbarImagesToBase64 = async (navbarData: NavbarData) => {
  const responseObj: NavbarData = {
    ...navbarData,
  };

  try {
    if (navbarData?.dark_logo?.data?.attributes?.url) {
      const darkLogo = getImageUrlForDownload(navbarData?.dark_logo);
      const darkLogoBase64 = await fetchURLAndCovertResponseToBase64(darkLogo);
      responseObj.dark_logo = {
        data: {
          attributes: {
            ...navbarData.dark_logo.data.attributes,
            url: darkLogoBase64,
          },
        },
      };
    }
    if (navbarData?.light_logo?.data?.attributes?.url) {
      const lightLogo = getImageUrlForDownload(navbarData?.light_logo);
      const lightLogoBase64 =
        await fetchURLAndCovertResponseToBase64(lightLogo);
      responseObj.light_logo = {
        data: {
          attributes: {
            ...navbarData.light_logo.data.attributes,
            url: lightLogoBase64,
          },
        },
      };
    }
    return responseObj;
  } catch (err) {
    return navbarData;
  }
};

export const convertWhyQuodArcaImagesToBase64 = async (bodyData: BodyData) => {
  const responseObj: BodyData = {
    ...bodyData,
  };
  try {
    if (bodyData?.hero_image?.data?.attributes?.url) {
      const heroImage = getImageUrlForDownload(bodyData?.hero_image);
      const heroImageBase64 =
        await fetchURLAndCovertResponseToBase64(heroImage);
      responseObj.hero_image = {
        data: {
          attributes: {
            ...bodyData.hero_image.data.attributes,
            url: heroImageBase64,
          },
        },
      };
    }
    if (bodyData?.tabs?.length) {
      const tabs = await Promise.all(
        bodyData.tabs.map(async (tab, index) => {
          if (!tab?.for) {
            return tab;
          }
          const icon = getImageUrlForDownload(tab?.icon);
          const activeIcon = getImageUrlForDownload(tab?.active_icon);
          const darkIcon = getImageUrlForDownload(tab?.dark_icon);
          const darkActiveIcon = getImageUrlForDownload(tab?.dark_active_icon);
          const darkMedia = getImageUrlForDownload(tab?.dark_media);
          const media = getImageUrlForDownload(tab?.media);
          const isFirstTab = index === 0;
          const newTab: any = {
            ...tab,
          };
          if (icon) {
            const iconBase64 = await fetchURLAndCovertResponseToBase64(icon);
            newTab.icon = {
              data: {
                attributes: {
                  ...tab.icon!.data.attributes,
                  url: iconBase64,
                },
              },
            };
          }
          if (activeIcon) {
            const activeIconBase64 =
              await fetchURLAndCovertResponseToBase64(activeIcon);
            newTab.active_icon = {
              data: {
                attributes: {
                  ...tab.active_icon!.data.attributes,
                  url: activeIconBase64,
                },
              },
            };
          }
          if (darkIcon) {
            const darkIconBase64 =
              await fetchURLAndCovertResponseToBase64(darkIcon);
            newTab.dark_icon = {
              data: {
                attributes: {
                  ...tab.dark_icon!.data.attributes,
                  url: darkIconBase64,
                },
              },
            };
          }
          if (darkActiveIcon) {
            const darkActiveIconBase64 =
              await fetchURLAndCovertResponseToBase64(darkActiveIcon);
            newTab.dark_active_icon = {
              data: {
                attributes: {
                  ...tab.dark_active_icon!.data.attributes,
                  url: darkActiveIconBase64,
                },
              },
            };
          }
          if (darkMedia && isFirstTab) {
            const darkMediaBase64 =
              await fetchURLAndCovertResponseToBase64(darkMedia);
            newTab.dark_media = {
              data: {
                attributes: {
                  ...tab.dark_media!.data.attributes,
                  url: darkMediaBase64,
                },
              },
            };
          }
          if (media && isFirstTab) {
            const mediaBase64 = await fetchURLAndCovertResponseToBase64(media);
            newTab.media = {
              data: {
                attributes: {
                  ...tab.media!.data.attributes,
                  url: mediaBase64,
                },
              },
            };
          }
          return newTab;
        }),
      );
      responseObj.tabs = tabs;
    }
    return responseObj;
  } catch (err) {
    return bodyData;
  }
};

export const convertAboutUsImagesToBase64 = async (bodyData: AboutUsBody) => {
  const responseObj: AboutUsBody = {
    ...bodyData,
  };

  try {
    if (bodyData?.section?.length) {
      const sections = await Promise.all(
        bodyData.section.map(async (section) => {
          if (!section?.media) {
            return section;
          }
          const media = getImageUrlForDownload(section?.media);
          const newSection: any = {
            ...section,
          };
          if (media) {
            const mediaBase64 = await fetchURLAndCovertResponseToBase64(media);
            newSection.media = {
              data: {
                attributes: {
                  ...section.media!.data.attributes,
                  url: mediaBase64,
                },
              },
            };
          }
          return newSection;
        }),
      );
      responseObj.section = sections;
    }
    if (bodyData?.card?.length) {
      const cards = await Promise.all(
        bodyData.card.map(async (card) => {
          if (!card?.image) {
            return card;
          }
          const image = getImageUrlForDownload(card?.image);
          const newCard: any = {
            ...card,
          };
          if (image) {
            const imageBase64 = await fetchURLAndCovertResponseToBase64(image);
            newCard.image = {
              data: {
                attributes: {
                  ...card.image!.data.attributes,
                  url: imageBase64,
                },
              },
            };
          }
          return newCard;
        }),
      );
      responseObj.card = cards;
    }
    return responseObj;
  } catch (err) {
    return bodyData;
  }
};

export const convertDownloadIosAndAndroidImagesToBase64 = async (
  bodyData: iosAndAndroidBody,
) => {
  const responseObj: iosAndAndroidBody = {
    ...bodyData,
  };

  try {
    if (bodyData?.image?.data?.attributes?.url) {
      const image = getImageUrlForDownload(bodyData?.image);
      const imageBase64 = await fetchURLAndCovertResponseToBase64(image);
      responseObj.image = {
        data: {
          attributes: {
            ...bodyData.image.data.attributes,
            url: imageBase64,
          },
        },
      };
    }
    if (bodyData?.dark_image?.data?.attributes?.url) {
      const darkImage = getImageUrlForDownload(bodyData?.dark_image);
      const darkImageBase64 =
        await fetchURLAndCovertResponseToBase64(darkImage);
      responseObj.dark_image = {
        data: {
          attributes: {
            ...bodyData.dark_image.data.attributes,
            url: darkImageBase64,
          },
        },
      };
    }
    if (bodyData?.mobile_image?.data?.attributes?.url) {
      const mobileImage = getImageUrlForDownload(bodyData?.mobile_image);
      const mobileImageBase64 =
        await fetchURLAndCovertResponseToBase64(mobileImage);
      responseObj.mobile_image = {
        data: {
          attributes: {
            ...bodyData.mobile_image.data.attributes,
            url: mobileImageBase64,
          },
        },
      };
    }
    if (bodyData?.dark_mobile_image?.data?.attributes?.url) {
      const darkMobileImage = getImageUrlForDownload(
        bodyData?.dark_mobile_image,
      );
      const darkMobileImageBase64 =
        await fetchURLAndCovertResponseToBase64(darkMobileImage);
      responseObj.dark_mobile_image = {
        data: {
          attributes: {
            ...bodyData.dark_mobile_image.data.attributes,
            url: darkMobileImageBase64,
          },
        },
      };
    }
    return responseObj;
  } catch (err) {
    return bodyData;
  }
};

export const convertDownloadIosAndAndroidFeaturesImagesToBase64 = async (
  featuresData: FeaturesData,
) => {
  const responseObj: FeaturesData = {
    ...featuresData,
  };
  try {
    if (!featuresData?.features?.length) {
      return featuresData;
    }
    const fullResponse = await Promise.all(
      featuresData.features.map(async (feature) => {
        const light_icon = getImageUrlForDownload(feature?.light_icon);
        const dark_icon = getImageUrlForDownload(feature?.dark_icon);
        const light_mobile_media = getImageUrlForDownload(
          feature?.light_mobile_media,
        );
        const dark_mobile_media = getImageUrlForDownload(
          feature?.dark_mobile_media,
        );

        const newFeature: any = {
          ...feature,
        };
        if (light_icon) {
          const darkIconBase64 =
            await fetchURLAndCovertResponseToBase64(light_icon);
          newFeature.light_icon = {
            data: {
              attributes: {
                ...feature.light_icon!.data.attributes,
                url: darkIconBase64,
              },
            },
          };
        }
        if (dark_icon) {
          const hoverStateIconBase64 =
            await fetchURLAndCovertResponseToBase64(dark_icon);
          newFeature.dark_icon = {
            data: {
              attributes: {
                ...feature.dark_icon!.data.attributes,
                url: hoverStateIconBase64,
              },
            },
          };
        }
        if (light_mobile_media) {
          const lightMobileMediaBase64 =
            await fetchURLAndCovertResponseToBase64(light_mobile_media);
          newFeature.light_mobile_media = {
            data: {
              attributes: {
                ...feature.light_mobile_media!.data.attributes,
                url: lightMobileMediaBase64,
              },
            },
          };
        }
        if (dark_mobile_media) {
          const darkMobileMediaBase64 =
            await fetchURLAndCovertResponseToBase64(dark_mobile_media);
          newFeature.dark_mobile_media = {
            data: {
              attributes: {
                ...feature.dark_mobile_media!.data.attributes,
                url: darkMobileMediaBase64,
              },
            },
          };
        }
        return newFeature;
      }),
    );
    responseObj.features = fullResponse;
    return responseObj;
  } catch (err) {
    return featuresData;
  }
};

export const convertDownloadMacAndWindowsImagesToBase64 = async (
  bodyData: MacAndWindowsBody,
) => {
  const responseObj: MacAndWindowsBody = {
    ...bodyData,
  };

  try {
    if (bodyData.image?.data?.attributes?.url) {
      const image = getImageUrlForDownload(bodyData.image);
      const imageBase64 = await fetchURLAndCovertResponseToBase64(image);
      responseObj.image = {
        data: {
          attributes: {
            ...bodyData.image.data.attributes,
            url: imageBase64,
          },
        },
      };
    }
    if (bodyData.dark_image?.data?.attributes?.url) {
      const retinaImage = getImageUrlForDownload(bodyData.dark_image);
      const retinaImageBase64 =
        await fetchURLAndCovertResponseToBase64(retinaImage);
      responseObj.dark_image = {
        data: {
          attributes: {
            ...bodyData.dark_image.data.attributes,
            url: retinaImageBase64,
          },
        },
      };
    }
    return responseObj;
  } catch (err) {
    return bodyData;
  }
};

export const convertDownloadMacAndWindowsFeaturesImagesToBase64 = async (
  featuresData: FeaturesData,
) => {
  const responseObj: FeaturesData = {
    ...featuresData,
  };
  try {
    if (!featuresData?.features?.length) {
      return featuresData;
    }
    const fullResponse = await Promise.all(
      featuresData.features.map(async (feature) => {
        const light_icon = getImageUrlForDownload(feature?.light_icon);
        const dark_icon = getImageUrlForDownload(feature?.dark_icon);

        const newFeature: any = {
          ...feature,
        };
        if (light_icon) {
          const darkIconBase64 =
            await fetchURLAndCovertResponseToBase64(light_icon);
          newFeature.light_icon = {
            data: {
              attributes: {
                ...feature.light_icon!.data.attributes,
                url: darkIconBase64,
              },
            },
          };
        }
        if (dark_icon) {
          const hoverStateIconBase64 =
            await fetchURLAndCovertResponseToBase64(dark_icon);
          newFeature.dark_icon = {
            data: {
              attributes: {
                ...feature.dark_icon!.data.attributes,
                url: hoverStateIconBase64,
              },
            },
          };
        }
        return newFeature;
      }),
    );
    responseObj.features = fullResponse;
    return responseObj;
  } catch (err) {
    return featuresData;
  }
};
