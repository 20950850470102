import { isProduction } from '@config/index';
import { SeverityLevel } from '@sentry/nextjs';
import { AxiosResponse } from 'axios';
import { NextApiRequest } from 'next';

const logger = require('pino')();

class Logger {
  static logRequest(req: NextApiRequest) {
    try {
      const message = `REQUEST [${req.method}] ${req.url}`;
      const additionalInfo = {
        headers: req.headers,
        body: req.body,
      };
      if (isProduction) logger.info(additionalInfo, message);
    } catch (err) {
      console.error(err);
    }
  }

  static logResponse(req: NextApiRequest, res: AxiosResponse<any, any>) {
    try {
      if (isProduction) logger.info(res);
    } catch (err) {
      console.error(err);
    }
  }

  static error(err: string, additionalInfo = {}) {
    try {
      if (isProduction) logger.error(additionalInfo, err);
    } catch (error) {
      console.error(error);
    }
  }

  static log(message: string, additionalInfo = {}) {
    try {
      if (isProduction) logger.info(additionalInfo, message);
    } catch (err) {
      console.error(err);
    }
  }

  static _formatMessage(
    level: SeverityLevel,
    message: string,
    additionalInfo: any = {},
  ) {
    try {
      const timestamp = new Date().toISOString();
      const formattedMessage = `[${timestamp}] [${level}] ${message}`;

      if (Object.keys(additionalInfo || {}).length) {
        return `${formattedMessage} | Additional Info: ${JSON.stringify(
          additionalInfo,
        )}`;
      }

      return formattedMessage;
    } catch (err) {
      console.error(err);
    }
  }
}

export default Logger;
