import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import HighlightContext from '../context/TextHighlighterContext';

interface IProps {
  reRender: boolean;
  setReRender: Dispatch<SetStateAction<boolean>>;
}

const useHighlightText = ({ reRender, setReRender }: IProps) => {
  const router = useRouter();
  const { allow } = useContext(HighlightContext);

  const highlightText = (text: string, color: string, id: string) => {
    if (document.getElementById(id)) {
      return;
    }
    const range = document.createRange();
    const sel = window.getSelection();
    if (!sel) return;
    sel.removeAllRanges();
    document.designMode = 'on';
    // @ts-ignore
    if (sel.rangeCount > 0 && sel?.getRangeAt) {
      range.setStart(sel.anchorNode!, sel.anchorOffset);
      range.setEnd(sel.focusNode!, sel.focusOffset);
    }

    if (range.toString() === '') {
      if (window.find && window.find(text, false, false)) {
        range.setStart(sel.anchorNode!, sel.anchorOffset);
        range.setEnd(sel.focusNode!, sel.focusOffset);
      }
    }
    if (range.toString() !== '') {
      const span = document.createElement('span');
      span.style.backgroundColor = color;
      span.id = id;
      range.surroundContents(span);
      sel.removeAllRanges();
    }
    document.designMode = 'off';
  };

  const reHit = async () => {
    await new Promise((resolve) => setTimeout(resolve, 500));
    setReRender(true);
  };

  useEffect(() => {
    const focused = router?.query?.focus as string;
    if (focused?.length) {
      // @ts-ignore
      let highlighted = focused.split('-').join(' ').split() || [];
      if (focused.includes('_and_')) {
        highlighted = focused.split('-').join(' ').split('and');
      }
      if (highlighted.length) {
        highlighted.forEach((txt, index) => {
          const color = allow ? 'yellow' : 'transparent';
          const id = `highlight-${index}`;
          highlightText(txt, color, id);
        });
      }
    }
    if (!reRender) {
      reHit();
    }
  }, [allow, router, reRender]);

  return null;
};

export default useHighlightText;
